/**
 * Created by aguerrero on 03/04/2016.
 */

angular
    .module('annexaApp')
    .controller('CouncillorAdminController', ['$scope', '$state', '$filter', 'DTColumnBuilder', '$rootScope', 'TableFilter', 'HeaderService', 'Language', 'CommonAdminModals', 'AnnexaFormlyFactory', 'AdminFactory', 'RestService', 'AnnexaModalFactory', 'dialogs', function ($scope, $state, $filter, DTColumnBuilder, $rootScope, TableFilter, HeaderService, Language, CommonAdminModals, AnnexaFormlyFactory, AdminFactory, RestService, AnnexaModalFactory, dialogs) {
        $rootScope.loading(false);
        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');
        $scope.dtDefCouncillors = new TableData('common','Councillor','./api/common/byfiler','rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>').setSort([[1, 'asc']]).setSortName(Language.getActiveColumn());

        var councillorNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
        var councillorActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                        new ActionButton('global.literals.see','viewCouncillor([data])','fa-eye'),
                                                        [new ActionButton('global.literals.remove','deleteCouncillor([data])')]);
        $scope.tableCouncillors = {
            dtColumns: [
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn(councillorNameColumn.getColumn()).withTitle(councillorNameColumn.getTitle()),
                DTColumnBuilder.newColumn('id').withOption('width', '12%').withOption('className', 'text-center').withTitle(councillorActionsColumn.getTitle()).notSortable().renderWith(councillorActionsColumn.getRender)
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux: { langColumn: Language.getActiveColumn()},
            reloadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = { langColumn: Language.getActiveColumn() };
                this.table.reloadData(function callbackInstance(json){}, resetPaging);
            }
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.councillors') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt mb-sm-pt mb-md-0 ',undefined,'#admin-councillors','fa fa-plus','global.commonAdmin.newCouncillor',undefined,'newCouncillor')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.newCouncillor = function () {
            var modal = CommonAdminModals.councillorNew;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            AnnexaFormlyFactory.showModal("modalCouncillor", modal, $scope.saveCouncillor, false);

        };

        $scope.viewCouncillor = function (id) {
            RestService.findOne('Councillor', id)
                .then(function (data) {
                    var modal = CommonAdminModals.councillorEdit;
                    modal.data = JSOG.decode(data.data);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.positions = AdminFactory.positions;
                    modal.updateCouncillor = $scope.updateCouncillor;
                    modal.loadFunction = function(value) {
                        if(!value) {
                            return [];
                        }

                        if(value != '*' && value.length < 3) {
                            return [];
                        }

                        if(value == '*') {
                            value = '';
                        }

                        return RestService.loadData('common', 'User', value)
                            .then(function(data) {
                                var users = [];

                                if(data.data && data.data.content && data.data.content.length > 0) {
                                    _.forEach(JSOG.decode(data.data.content), function(val) {
                                        var name = val.name + ' ' + val.surename1;

                                        if(val.surename2) {
                                            name += ' ' + val.surename2;
                                        }

                                        users.push({ 'id':  val.id, 'user': val, 'value': name });
                                    });
                                }

                                return users;
                            }).catch(function() {
                                return [];
                            });

                    }

                    var councilManAux = { user: undefined };
                    if(modal.data.councilMan) {
                        var user_name = '';
                        user_name = modal.data.councilMan.name + ' ' + modal.data.councilMan.surename1 + (modal.data.councilMan.surename2 ? ' ' + modal.data.councilMan.surename2 : '');
                        councilManAux = { user: { id: modal.data.councilMan.id, user: modal.data.councilMan, value: user_name }};
                    }
                    modal.councilManAux = councilManAux;
                    AnnexaModalFactory.showModal('modalCouncillorEdit', modal);
                });
        };

        $scope.deleteCouncillor = function(id) {
            var dlg = dialogs.confirm(
                $filter('translate')('global.commonAdmin.literals.deleteCouncillor'),
                $filter('translate')('global.commonAdmin.literals.confirmDeleteCouncillor'),
                {
                    animation: true,
                    background: 'static',
                    keyboard: true,
                    size: ''
                }
            )

            dlg.result.then(function (btn) {
                AdminFactory.deleteCouncillor(id)
                    .then(function (data) {
                        $scope.doSearchFilter();
                    }).catch(function (error) {
                });
            }, function (btn) {
            });

        };

        $scope.updateCouncillor = function (val, prop) {
            if(prop == 'councilMan') {
                var model = this.data;

                if(val && val.user && val.user.id) {
                    model.councilMan = { id: val.user.id };

                    $scope.saveCouncillor(model);
                } else {
                    return $filter('translate')('global.validation.required');
                }
            } else if(prop == 'councilManPosition') {
                var model = this.data;

                if(val) {
                    model.councilManPosition = val;

                    $scope.saveCouncillor(model);
                } else {
                    return $filter('translate')('global.validation.required');
                }
            } else {
                $scope.saveCouncillor(val);
            }
        }

        $scope.saveCouncillor = function (councillor) {
            var model = { language1: 'NotTranslated', language2: 'NotTranslated', language3: 'NotTranslated', councilMan: undefined};
            var isNew = false;
            var modal = undefined;
            var isValid = false;
            if (councillor.hasOwnProperty('annexaFormly') && councillor.annexaFormly.form.$valid) {
                modal = this;
                if (councillor.annexaFormly.model.modal_body.language1) {
                    model.language1 = councillor.annexaFormly.model.modal_body.language1;
                }
                if (councillor.annexaFormly.model.modal_body.language2) {
                    model.language2 = councillor.annexaFormly.model.modal_body.language2;
                }
                if (councillor.annexaFormly.model.modal_body.language3) {
                    model.language3 = councillor.annexaFormly.model.modal_body.language3;
                }
                if (councillor.annexaFormly.model.modal_body.councilMan && councillor.annexaFormly.model.modal_body.councilMan.user) {
                    model.councilMan = councillor.annexaFormly.model.modal_body.councilMan.user;
                }
                if (councillor.annexaFormly.model.modal_body.councilManPosition) {
                    model.councilManPosition = {id: councillor.annexaFormly.model.modal_body.councilManPosition};
                }
                isNew = true;
                isValid = true;
            } else if(councillor.hasOwnProperty('annexaFormly')){
                isValid = false;
            }else{
                model = councillor;
                isValid = true;
            }
            if(isValid ){
                AdminFactory.newCouncillor(model)
                    .then(function (data) {
                        $scope.doSearchFilter();
                        if (isNew && modal) {
                            modal.close();
                        }
                    }).catch(function (error) {
                });
            }
        };

        $scope.doSearchFilter=function(queued) {
            $scope.tableCouncillors.reloadInternalData(true);
        }
    }]);
