/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('JobUploadDocumentsAdminController',['$scope', '$rootScope', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'Language', 'CommonAdminService',
        function($scope, $rootScope, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, Language, CommonAdminService) {
            $scope.showLoadingdiv = false;
            $scope.languageColumn = Language.getActiveColumn();
            $scope.tfilter = new TableFilter();
            $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');

            $scope.dtDefHighLevelQueue = new TableData('jobs', 'JobUploadDocument', './api/doc/byfilter').setSort([[1, 'asc']]).setSortName('id');

            var dateColumn = new DateColumn($filter, 'global.literals.createdDate');
            var renderErrorCounter = function(data, type, full, meta){
            	var content = 0;
            	if(data){
                    if(data <= 10) {
                    	content = 11 - data;
                    }
                }
                return content;
            }
            var renderStatus = function(data, type, full, meta){
            	var content = '';
            	if(data){
            		if(data == 'ERROR' && full.errorMessage) {
            			var tooltip = full.errorMessage.substring(0,250) + '....';
            			
            			content += '<b tooltip-class="customClass" uib-tooltip="' + tooltip + '" tooltip-placement="top" aria-label="' + tooltip + '"">';
            			content += $filter('translate')('global.commonAdmin.literals.' + data);
            			content += '</b>';
            		} else {
                		content = $filter('translate')('global.commonAdmin.literals.' + data)
            		}
                }
                return content;
            }
            var DocumentTypesActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.retry', 'retry([data])', 'fa-play'));

            $scope.tableHighLevelQueue = {
                dtColumns: [
                    DTColumnBuilder.newColumn('id').withTitle("Id").withOption('width', '6%'),
                    DTColumnBuilder.newColumn('createdDate').withTitle(dateColumn.getTitle()).renderWith(dateColumn.getRender),
                    DTColumnBuilder.newColumn('registerEntry.id').withTitle($filter('translate')('global.commonAdmin.literals.registerIdtitle')),
                    DTColumnBuilder.newColumn('document.id').withTitle($filter('translate')('global.commonAdmin.literals.documentIdTitle')),
                    DTColumnBuilder.newColumn('fileName').withTitle($filter('translate')('global.commonAdmin.literals.fileNameTitle')),
                    DTColumnBuilder.newColumn('status').withTitle($filter('translate')('global.commonAdmin.literals.statusTitle')).renderWith(renderStatus),
                    DTColumnBuilder.newColumn('errorCounter').withTitle($filter('translate')('global.commonAdmin.literals.errorCountTitle')).renderWith(renderErrorCounter),
                    DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(DocumentTypesActionsColumn.getTitle()).renderWith(DocumentTypesActionsColumn.getRender).notSortable()
                ],
                filterCall: $scope.tfilter.getFilterCall(),
                filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn},
                reloadInternalData: function (resetPaging) {
                    this.filterCall = $scope.tfilter.getFilterCall();
                    this.filterCallAux = $scope.tfilter.getFilterCallAux();
                    this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                    this.filterCallAux.langColumn = $scope.languageColumn;
                    this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                }
            };

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.admin.high_level_queue') {
                    $rootScope.subHeadButtons = [
                        new HeadButton('btn primary breadDivButton ml-sm-pt mb-sm-pt mb-md-0',undefined,"#tableHighLevelQueue",'fa fa-play','global.literals.retryAll',undefined,'retryAll'),
                    ];
                }
            });

            HeaderService.changeState($state.current);

            $scope.retry = function(id) {
            	CommonAdminService.retryJobUploadDocument(id)
	            	.then(function (data) {
	            		$scope.tableHighLevelQueue.reloadInternalData(true);
	            }).catch(function (error) {
	            });
            }
            
            $scope.retryAll = function() {
            	CommonAdminService.retryAllJobUploadDocument()
	            	.then(function (data) {
	            		$scope.tableHighLevelQueue.reloadInternalData(true);
	            }).catch(function (error) {
	            });
            }
            
        }]);