/**
 * Created by osirvent on 30/11/2015.
 */
angular
    .module('annexaApp')
    .config(['$stateProvider', function($stateProvider) {
        $stateProvider
        .state('annexa.admin', {
            url: '/admin',
            views: {
                "": {
                    templateUrl: './views/layout/admin.html',
                    controller: 'AdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.title',
                authenticate: true,
                permissions: {
                    only: ['admin_users', 'admin_profiles', 'admin_roles', 'admin_sections', 'admin_councillors', 'admin_doc_templates', 'admin_doc_types', 'admin_doc_template_types', 'admin_sign_preset_circuits', 'admin_tram_family_procedures','admin_tram_guided_procedures','admin_tram_non_guided_procedures', 'admin_tram_templates','admin_archive_classification','admin_regiser_entry_classification','admin_task_templates','admin_tram_transaction_type','semic','admin_sec','admin_positions','admin_notifications','admin_register_diligence_types','admin_register_document_type','admin_entity_holidays','admin_aspects','admin_audit','admin_reg_office','admin_tram_role_interested','admin_register_channel','si_query_adm','si_report_adm','admin_book_type','admin_delegation','admin_user_account_instance','admin_operation_type','admin_high_level_queue','admin_diligence_stamp_type', 'admin_related_dossier_relation', 'admin_address_type', 'admin_country', 'admin_street_type', 'admin_street_pseudoVia', 'admin_street_state', 'admin_street_city','admin_street_street', 'admin_territorial_group', 'admin_territorial_group_type', 'admin_predefined_filters']
                }
            }
        })
        .state('annexa.admin.users', {
            url: '/users',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/users.html',
                    controller: 'UserController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.users',
                authenticate: true,
                permissions: {
                    only: 'admin_users'
                }
            },
            resolve: {
                getUsers: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getUsers();
                }],
                getPoliticalParties: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getPoliticalParties();
                }],
				loadDashboard:['GlobalDataFactory', function(GlobalDataFactory) {
                    return GlobalDataFactory.passwordMessages(true);
                }]

            }
        })
        .state('annexa.admin.profiles', {
            url: '/profiles',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/profiles.html',
                    controller: 'ProfileAdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.profiles',
                authenticate: true,
                permissions: {
                    only: 'admin_profiles'
                }
            }
        })
        .state('annexa.admin.sections', {
            url: '/sections',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sections.html',
                    controller: 'SectionController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.sections',
                authenticate: true,
                permissions: {
                    only: 'admin_sections'
                }
            },
            resolve: {
                getSections: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getSections();
                }]
            }
        })
        .state('annexa.admin.template_types', {
            url: '/templatetypes',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/templatetypes.html',
                    controller: 'TemplateTypesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.templateTypes',
                authenticate: true,
                permissions: {
                    only: 'admin_doc_template_types'
                }
            },
            resolve: {
                getTemplatetypes: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getTemplateTypes();
                }]
            }
        })
        .state('annexa.admin.templates', {
            url: '/templates',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/templates.html',
                    controller: 'TemplateAdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.templates',
                authenticate: true,
                permissions: {
                    only: 'admin_doc_templates'
                }
            },
            resolve: {
                newTemplate: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.newDocTemplate();
                }]
            }
        })
        .state('annexa.admin.councillors', {
            url: '/councillors',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/councillors.html',
                    controller: 'CouncillorAdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.councillors',
                authenticate: true,
                permissions: {
                    only: 'admin_councillors'
                }
            },
            resolve: {
                getPositions: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getPositions();
                }]

            }
        })
        .state('annexa.admin.presetSignCircuits', {
            url: '/presetSignCircuits',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/presetSignCircuits.html',
                    controller: 'PresetSignCircuitsController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.presetSignCircuits',
                authenticate: true,
                permissions: {
                    only: 'admin_sign_preset_circuits'
                }
            },
            resolve: {
                newPresetSignCircuit: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.newPresetSignCircuit();
                }]
            }
        })
        .state('annexa.admin.tram_templates', {
            url: '/tram_templates',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/tram_templates.html',
                    controller: 'TramTemplatesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.templates',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_templates'
                }
            }
        })
        .state('annexa.admin.roles', {
            url: '/roles',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/roles.html',
                    controller: 'RolesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.rols',
                authenticate: true,
                permissions: 'admin_roles'
            },
            resolve: {
                getRoles: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getRoles();
                }]
            }
        })
        .state('annexa.admin.sec_footer_claim', {
            url: '/sec_footer_claim',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sec_footer_claims.html',
                    controller: 'SecFooterClaimsController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.sec.literals.footerClaims',
                authenticate: true,
                permissions: {
                    only: 'admin_sec'
                }
            }
        })
        .state('annexa.admin.procedure_families', {
            url: '/procedureFamilies',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/procedureFamilies.html',
                    controller: 'ProcedureFamiliesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.familyProcedure',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_family_procedures'
                }
            },
            resolve: {
                getFamilyProcedures: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getFamilyProceduresReload();
                }]
            }
        })
        .state('annexa.admin.unguided_procedure', {
            url: '/unguidedProcedure',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/unguidedProcedure.html',
                    controller: 'UnguidedProcedureController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.unguidedProcedure',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_non_guided_procedures'
                }
            }
        })
        .state('annexa.admin.guided_procedure', {
            url: '/guidedProcedure',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/guidedProcedure.html',
                    controller: 'GuidedProcedureController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.guidedProcedure',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_guided_procedures'
                }
            }
        })
        .state('annexa.admin.guided_procedure.new_guided_procedure',{
            url: '/new/:procedure',
            views: {
                "mainadminProcedure@annexa.admin.guided_procedure": {
                    templateUrl: './views/layout/guidedProcedureBPM.html',
                    controller: 'NewGuidedProcedureController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.newGuidedProcedure',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_guided_procedures'
                }
            },
            resolve: {
                guidedProcedure:['$state', '$stateParams', 'AdminFactory', function($state, $stateParams, AdminFactory) {
                    var procedure = -1;

                    if($stateParams.procedure) {
                        this.data.displayName = 'global.commonAdmin.titleEditProcedure';
                        procedure = $stateParams.procedure;
                    }

                    return AdminFactory.setupGuidedProcedures(procedure);
                }]
            }
        })
        .state('annexa.admin.unguided_procedure.new_unguided_procedure', {
            url: '/new_unguided_procedure',
            views: {
                "mainadminUnguidedProcedure@annexa.admin.unguided_procedure": {
                    templateUrl: './views/layout/newUnguidedProcedure.html',
                    controller: 'NewUnguidedProcedureController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.newUnguidedProcedure',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_non_guided_procedures'
                }
            }
        })
        .state('annexa.admin.unguided_procedure.edit_unguided_procedure', {
            url: '/edit_unguided_procedure/:procedure',
            views: {
                "mainadminUnguidedProcedure@annexa.admin.unguided_procedure": {
                    templateUrl: './views/layout/editUnguidedProcedure.html',
                    controller: 'EditUnguidedProcedureController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.editUnguidedProcedure',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_non_guided_procedures'
                }
            },
            resolve: {
                procedure:['$q', '$state', '$stateParams', 'CustomFieldFactory', '$timeout', function($q, $state, $stateParams, CustomFieldFactory, $timeout) {
                    if($stateParams.procedure) {
                        return CustomFieldFactory.getProcedure($stateParams.procedure);
                    } else {
                        $timeout(function() { $state.go('annexa.admin.unguided_procedure'); });
                        return $q.reject("No procedure");
                    }
                }]
            }
        })
        .state('annexa.admin.guided_procedure.edit_guided_procedure', {
            url: '/edit_guided_procedure/:procedure',
            views: {
                "mainadminGuidedProcedure@annexa.admin.guided_procedure": {
                    templateUrl: './views/layout/editGuidedProcedure.html',
                    controller: 'EditGuidedProcedureController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.editGuidedProcedure',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_guided_procedures'
                }
            },
            resolve: {
                procedure:['$q', '$state', '$stateParams', 'CustomFieldFactory', '$timeout', function($q, $state, $stateParams, CustomFieldFactory, $timeout) {
                    if($stateParams.procedure) {
                        return CustomFieldFactory.getProcedure($stateParams.procedure);
                    } else {
                        $timeout(function() { $state.go('annexa.admin.guided_procedure'); });
                        return $q.reject("No procedure");
                    }
                }]
            }
        })        
        .state('annexa.admin.role_interested', {
            url: '/roleInterested',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/roleInterested.html',
                    controller: 'RoleInterestedController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.roleInterested',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_role_interested'
                }
            }
        })        
        .state('annexa.admin.document_types', {
            url: '/documentTypes',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/documentTypes.html',
                    controller: 'DocumentTypesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.documentTypes',
                authenticate: true,
                permissions: {
                    only: 'admin_doc_types'
                }
            }
        })
        .state('annexa.admin.classification', {
            url: '/classification',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/classification.html',
                    controller: 'ClassificationController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.classification',
                authenticate: true
            }
        })
        .state('annexa.admin.registerClassification', {
            url: '/registerClassification',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/registerClassification.html',
                    controller: 'RegisterEntryClassificationController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.classifications',
                authenticate: true,
                permissions: {
                    only: 'admin_regiser_entry_classification'
                }
            }
        })
        .state('annexa.admin.task_templates', {
            url: '/task_templates',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/taskTemplates.html',
                    controller: 'TaskTemplateController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.templates',
                authenticate: true,
                permissions: {
                    only: 'admin_task_templates'
                }
            },
            resolve: {
                getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                    return GlobalDataFactory.initializeTaskLists();
                }]
            }
        })
        .state('annexa.admin.transaction_type', {
            url: '/transactionType',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/transactionTypes.html',
                    controller: 'TransactionTypeController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.transactionType',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_transaction_type'
                }
            },
            resolve: {
                getTramitationPhases: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getTramitationPhases();
                }]
            }
        })
        .state('annexa.admin.entity_holidays', {
            url: '/entityHolidays',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/entityHolidays.html',
                    controller: 'EntityHolidaysController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.entityHolidays',
                authenticate: true,
                permissions: {
                    only: 'admin_entity_holidays'
                }
            },
            resolve: {
                entityEvents: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getEntityEvents();
                }]
            }
        })
        .state('annexa.admin.sec_organs', {
            url:'/sec_organs',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sec_organs.html',
                    controller: 'SecOrgansController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.sec.literals.organs',
                authenticate: true,
                permissions: {
                    only: 'admin_sec'
                }
            },
            resolve: {
                getPoliticalParties: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getPoliticalParties();
                }]
            }
        })
        .state('annexa.admin.sec_organ_member_rols', {
            url: '/sec_organ_member_rols',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sec_organ_member_rols.html',
                    controller: 'SecOrganMemberRolsController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.sec.literals.organMemberRols',
                authenticate: true,
                permissions: {
                    only: 'admin_sec'
                }
            }
        })
        .state('annexa.admin.sec_political_parties', {
            url: '/sec_political_parties',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sec_political_parties.html',
                    controller: 'SecPoliticalPartiesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.sec.literals.politicalParties',
                authenticate: 'true',
                permissions: {
                    only: 'admin_sec'
                }
            }
        })
        .state('annexa.admin.sec_session_types', {
            url: '/sec_session_types',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sec_session_types.html',
                    controller: 'SecSessionTypesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.sec.literals.sessionTypes',
                authenticate: true,
                permissions: {
                    only: 'admin_sec'
                }
            }
        })
        .state('annexa.admin.decree_delegated_type', {
            url: '/decree_delegated_type',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/decree_delegated_type.html',
                    controller: 'DecreeDelegatedTypeController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.sec.literals.decreeDelegatedTypes',
                authenticate: true,
                permissions: {
                    only: 'admin_decree_delegated_type'
                }
            }
        })
        .state('annexa.admin.sec_proposal_types', {
            url: '/sec_proposal_types',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sec_proposal_types.html',
                    controller: 'SecProposalTypesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.sec.literals.proposalTypes',
                authenticate: true,
                permissions: {
                    only: 'admin_sec'
                }
            },
            resolve: {
                loadAdmin: ['SecFactory', function (SecFactory) {
                    return SecFactory.loadAdmin('annexa.admin.sec_organs');
                }]
            }
        })
        .state('annexa.admin.sec_members_organ', {
            url: '/sec_members_organ',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sec_members_organ.html',
                    controller: 'SecMembersOrganController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.sec.literals.members',
                authenticate: true,
                permissions: {
                    only: 'admin_sec'
                }
            },
            resolve: {
                loadAdmin: ['SecFactory', function (SecFactory) {
                    return SecFactory.loadAdmin('annexa.admin.sec_members_organ');
                }]
            }
        })
        .state('annexa.admin.sec_meeting_locations', {
            url: '/sec_meeting_locations',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sec_meeting_locations.html',
                    controller: 'SecMeetingLocationsController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.sec.literals.meetingLocations',
                authenticate: true,
                permissions: {
                    only: 'admin_sec'
                }
            }
        })
        .state('annexa.admin.aspects', {
            url: '/aspects',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/aspects.html',
                    controller: 'AspectsController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.aspects',
                authenticate: true,
                permissions: {
                    only: 'admin_aspects'
                }
            },
            resolve: {
                getCustomFields: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getCustomFields();
                }]
            }
        })
        .state('annexa.admin.notification_types', {
            url: '/notification_types',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/notification_types.html',
                    controller: 'NotificationTypesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.notificationTypes',
                authenticate: true,
                permissions: {
                    only: 'admin_notifications'
                }
            }
        })
        .state('annexa.admin.sec_templates',{
            url: '/sec_templates',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/sec_templates.html',
                    controller: 'SecTemplateController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.templates',
                authenticate: true,
                permissions: {
                    only: 'admin_sec'
                }
            },
            resolve: {
                loadAdmin: ['SecFactory', function (SecFactory) {
                    return SecFactory.loadAdmin('annexa.admin.sec_templates');
                }]
            }
        })
        .state('annexa.admin.positions', {
            url: '/positions',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/positions.html',
                    controller: 'PositionsController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.positions',
                authenticate: true,
                permissions: {
                    only: 'admin_positions'
                }
            }
        })
        .state('annexa.admin.configuration', {
            url: '/configuration',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/configuration.html',
                    controller: 'ConfigurationController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.commonAdmin.tabs.configuration',
                authenticate: true,
                permissions: {
                    only: 'semic'
                }
            },
            resolve: {
                getConfigurationGroups: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getConfigurationGroups();
                }],
                getCustomFields: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getCustomFields();
                }]
            }
        })
        .state('annexa.admin.register_diligence_types', {
            url: '/registerDiligenceTypes',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/diligenceTypes.html',
                    controller: 'DiligenceTypesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.diligenceType',
                authenticate: true,
                permissions: {
                    only: 'admin_register_diligence_types'
                }
            }
        })
        .state('annexa.admin.register_entry_channel', {
            url: '/registerChannels',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/registerChannels.html',
                    controller: 'RegisterChannelsController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.registerChannel',
                authenticate: true,
                permissions: {
                    only: 'admin_register_channel'
                }
            }
        })
        .state('annexa.admin.register_document_type', {
            url: '/register_document_type',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/registerDocumentType.html',
                    controller: 'RegisterDocumentTypeController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.regType',
                authenticate: true,
                permissions: {
                    only: 'admin_register_document_type'
                }
            }
        })
        .state('annexa.admin.register_entry_office', {
            url: '/registerOffice',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/registerOffice.html',
                    controller: 'RegisterEntryOfficeController'
                }
            },

            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.register_office',
                authenticate: true,
                permissions: {
                    only: 'admin_reg_office'
                }
            },
        })
        .state('annexa.admin.audit_documents',{
                url: '/audit_documents/:objectId',
                views: {
                    "mainadmin@annexa.admin": {
                        templateUrl: './views/layout/audit_documents.html',
                        controller: 'AuditController'
                    }
                },
                data: {
                    title: 'global.commonAdmin.toptitle',
                    displayName: 'global.literals.audit_documents',
                    authenticate: true,
                    permissions: {
                        only: 'admin_audit'
                    }
                }
            })
        .state('annexa.admin.audit_register_entry',{
                url: '/audit_register_entry/:objectId',
                views: {
                    "mainadmin@annexa.admin": {
                        templateUrl: './views/layout/audit_register_entry.html',
                        controller: 'AuditController'
                    }
                },
                data: {
                    title: 'global.commonAdmin.toptitle',
                    displayName: 'global.literals.audit_register_entry',
                    authenticate: true,
                    permissions: {
                        only: 'admin_audit'
                    }
                }
            })
        .state('annexa.admin.audit_diligence',{
            url: '/audit_diligence/:objectId',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/audit_diligence.html',
                    controller: 'AuditController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.audit_diligence',
                authenticate: true,
                permissions: {
                    only: 'admin_audit'
                }
            }
        })
        .state('annexa.admin.audit_third',{
            url: '/audit_third/:objectId',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/audit_third.html',
                    controller: 'AuditController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.audit_third',
                authenticate: true,
                permissions: {
                    only: 'admin_audit'
                }
            }
        })
        .state('annexa.admin.audit_dossier',{
            url: '/audit_dossier/:objectId',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/audit_dossier.html',
                    controller: 'AuditController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.audit_dossier',
                authenticate: true,
                permissions: {
                    only: 'admin_audit'
                }
            }
        })
        .state('annexa.admin.audit_dossier_transaction',{
            url: '/audit_dossier_transaction/:objectId',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/audit_dossier_transaction.html',
                    controller: 'AuditController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.audit_dossier_transaction',
                authenticate: true,
                permissions: {
                    only: 'admin_audit'
                }
            }
        })
        .state('annexa.admin.audit_proposals',{
            url: '/audit_proposals/:objectId',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/audit_proposals.html',
                    controller: 'AuditController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.audit_proposals',
                authenticate: true,
                permissions: {
                    only: 'admin_audit'
                }
            }
        })
        .state('annexa.admin.audit_decree',{
            url: '/audit_decree/:objectId',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/audit_decree.html',
                    controller: 'AuditController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.audit_decree',
                authenticate: true,
                permissions: {
                    only: 'admin_audit'
                }
            }
        })
        .state('annexa.admin.audit_task',{
            url: '/audit_task/:objectId',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/audit_task.html',
                    controller: 'AuditController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.audit_task',
                authenticate: true,
                permissions: {
                    only: 'admin_audit'
                }
            }
        })
		.state('annexa.admin.audit_right_access',{
            url: '/audit_right_access/:objectId',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/audit_right_access.html',
                    controller: 'AuditController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.audit_right_access',
                authenticate: true,
                permissions: {
                    only: 'admin_audit'
                }
            }
        })
		.state('annexa.admin.audit_address',{
            url: '/audit_address/:objectId',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/audit_address.html',
                    controller: 'AuditController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.audit_address',
                authenticate: true,
                permissions: {
                    only: 'admin_audit'
                }
            }
        })
        .state('annexa.admin.query', {
            url: '/query',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/queries.html',
                    controller: 'QueryController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.queries',
                authenticate: true,
                permissions: {
                    only: 'si_query_adm'
                }
            },
            resolve: {
                getQueryParameters: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getQueryParameters();
                }],
                getQueryConnectors: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getQueryConnectors();
                }]
            }
        })
        .state('annexa.admin.report', {
            url: '/report',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/reports.html',
                    controller: 'ReportController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.reports',
                authenticate: true,
                permissions: {
                    only: 'si_report_adm'
                }
            }
        })
        .state('annexa.queryTables', {
            url: '/queryTables/:objectId',
            templateUrl: './views/layout/tablesQuerys.html',
            controller: 'QueryTablesController',
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.querys.tables.tableQuery',
                authenticate: true,
                permissions: {
                    only: 'si_query_adm'
                }
            },
            resolve: {
                getQueryTablesInformation: ['AdminFactory', '$stateParams', function (AdminFactory, $stateParams) {
                	if($stateParams.objectId) {
                        return AdminFactory.getQueryTablesInformation($stateParams.objectId);
                    } else {
                        return AdminFactory.getQueryTablesInformation();
                    }
                }]
            }
        })
        .state('annexa.admin.query_connector', {
            url: '/query_connector',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/queryConnectors.html',
                    controller: 'QueryConnectorController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.queries',
                authenticate: true,
                permissions: {
                    only: 'admin_query_connector'
                }
            }
        })
        .state('annexa.admin.booktype', {
            url: '/booktype',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/booktypes.html',
                    controller: 'BooskAdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.books.admin.booktype',
                authenticate: true,
                permissions: {
                    only: 'admin_book_type'
                }
            }
        })
        .state('annexa.admin.delegation', {
            url: '/delegation',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/delegation.html',
                    controller: 'DelegationAdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.delegate.activated_delegations',
                authenticate: true,
                permissions: {
                    only: 'admin_delegation'
                }
            }
        })
        .state('annexa.admin.high_level_queue', {
            url: '/high_level_queue',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/high_level_queue.html',
                    controller: 'JobUploadDocumentsAdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.documents.highLevelQueueTittle',
                authenticate: true,
                permissions: {
                    only: 'admin_high_level_queue'
                }
            }
        })
        .state('annexa.admin.operationType', {
            url: '/operationType',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/operationType.html',
                    controller: 'OperationTypeAdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.operationType.operationType',
                authenticate: true,
                permissions: {
                    only: 'admin_operation_type'
                }
            }
        })
        .state('annexa.admin.userAccountingInstance', {
            url: '/userAccountingInstance',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/userAccountingInstance.html',
                    controller: 'UserAccountingInstanceAdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.userAccountingInstace.displayName',
                authenticate: true,
                permissions: {
                    only: 'admin_user_account_instance'
                }
            },
            resolve: {
            	getAccountInstances: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getAccountInstances();
                }]
            }
        })
        .state('annexa.admin.diligence_stamp_type', {
            url: '/diligence_stamp_type',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/diligence_stamp_type.html',
                    controller: 'DiligenceStampTypeController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.diligence_stamp_type.title',
                authenticate: true,
                permissions: {
                    only: 'admin_diligence_stamp_type'
                }
            }
        })
        .state('annexa.admin.task_types', {
            url: '/task_types',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/task_types.html',
                    controller: 'TaskTypesController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.taskTypes',
                authenticate: true,
                permissions: {
                    only: 'admin_task_types'
                }
            },
            resolve: {
                getTaskTypes: ['AdminFactory', function (AdminFactory) {
                    return AdminFactory.getTaskTypes();
                }],
                getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                    return GlobalDataFactory.initializeTaskLists();
                }]
            }
        })
        .state('annexa.admin.task_origin', {
            url: '/task_origin',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/task_origin.html',
                    controller: 'TaskOriginController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.taskOrigins',
                authenticate: true,
                permissions: {
                    only: 'admin_task_origin'
                }
            }
        })
        .state('annexa.admin.address_type', {
            url: '/address_type',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/address_type.html',
                    controller: 'AddressTypeController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.addressTypes',
                authenticate: true,
                permissions: {
                    only: 'admin_address_type'
                }
            }
        })
        .state('annexa.admin.relatedDossierRelation', {
            url: '/relatedDossierRelation',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/relatedDossierRelation.html',
                    controller: 'RelatedDossierRelationController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.relatedDossierRelation.displayName',
                authenticate: true,
                permissions: {
                    only: 'admin_related_dossier_relation'
                }
            }
        })
        .state('annexa.admin.country', {
            url: '/country',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/country.html',
                    controller: 'CountryController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.country',
                authenticate: true,
                permissions: {
                    only: 'admin_country'
                }
            }
        })
        .state('annexa.admin.streetType', {
            url: '/streetType',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/streetType.html',
                    controller: 'StreetTypeController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.thirds.literals.streetType',
                authenticate: true,
                permissions: {
                    only: 'admin_street_type'
                }
            }
        })
        .state('annexa.admin.pseudoVia', {
            url: '/pseudoVia',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/pseudoVia.html',
                    controller: 'PseudoViaController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.pseudoVies',
                authenticate: true,
                permissions: {
                    only: 'admin_street_pseudoVia'
                }
            }
        }) 
        .state('annexa.admin.state', {
            url: '/state',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/state.html',
                    controller: 'StateController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.states',
                authenticate: true,
                permissions: {
                    only: 'admin_street_state'
                }
            },
            resolve: {
                getTaskTypes: ['TerritoryFactory', function (TerritoryFactory) {
                    return TerritoryFactory.initializeListsState();
                }]
            }
        })
        .state('annexa.admin.city', {
            url: '/city',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/city.html',
                    controller: 'CityController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.cities',
                authenticate: true,
                permissions: {
                    only: 'admin_street_city'
                }
            },
            resolve: {
                getTaskTypes: ['TerritoryFactory', function (TerritoryFactory) {
                    return TerritoryFactory.initializeListsCity();
                }]
            }
        })
        .state('annexa.admin.street', {
            url: '/street',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/street.html',
                    controller: 'StreetController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.streets',
                authenticate: true,
                permissions: {
                    only: 'admin_street_street'
                }
            },
            resolve: {
                getTaskTypes: ['TerritoryFactory', function (TerritoryFactory) {
                    return TerritoryFactory.initializeListsStreet();
                }]
            }
        })
		.state('annexa.admin.territorial_group_type', {
            url: '/territorialGroupType',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/territorialGroupType.html',
                    controller: 'TerritorialGroupTypeController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.territorialGroupType',
                authenticate: true,
                permissions: {
                    only: 'admin_territorial_group_type'
                }
            }
        })
		.state('annexa.admin.territorial_group', {
            url: '/territorialGroup',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/territorialGroup.html',
                    controller: 'TerritorialGroupController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.territorialGroup',
                authenticate: true,
                permissions: {
                    only: 'admin_territorial_group'
                }
            },
            resolve: {
                getTaskTypes: ['TerritoryFactory', function (TerritoryFactory) {
                    return TerritoryFactory.initializeListsTerritorialGroup();
                }]
            }
        })
		.state('annexa.admin.predefined_filters', {
            url: '/predefinedFilters',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/predefinedFilters.html',
                    controller: 'PredefinedFiltersController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.predefinedFilters',
                authenticate: true,
                permissions: {
                    only: 'admin_predefined_filters'
                }
            },
            resolve: {
                getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                    return GlobalDataFactory.initializeTaskLists();
                }]
            }
        })
        .state('annexa.admin.tram_classification', {
            url: '/tram_classification',
            views: {
                "mainadmin@annexa.admin": {
                    templateUrl: './views/layout/tram_classification.html',
                    controller: 'TramClassificationAdminController'
                }
            },
            data: {
                title: 'global.commonAdmin.toptitle',
                displayName: 'global.literals.tramClassification',
                authenticate: true,
                permissions: {
                    only: 'admin_tram_classification'
                }
            }
        });
        
    }]);