angular
    .module('annexaApp')
    .controller('TaskTypesController',['$scope', '$rootScope', '$state', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'CustomFieldFactory', 'HelperService', 'HeaderService', 'CommonService', 'AdminFactory', 'dialogs',
    						   function($scope, $rootScope, $state, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, CustomFieldFactory, HelperService, HeaderService, CommonService, AdminFactory, dialogs) {
        $rootScope.loading(false);
        $scope.taskTypes = [];
        $scope.langColumn = Language.getActiveColumn();

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.task_types') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#admin-task-types','fa fa-plus','global.commonAdmin.modal.taskType.titleNew',undefined,'newTaskType'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#admin-task-types','fa fa-download','global.literals.export',undefined,'exportTaskTypes')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.taskTypes = CommonService.getNestableData(AdminFactory.taskTypes, 'taskType', 'orderView');

        $scope.toggle = function (scope) {
            scope.toggle();
        };

        $scope.newTaskType = function (node) {
            var modal = angular.copy(CommonAdminModals.taskTypeNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.model.modal_body = { column1: { profiles: [] }, column2: { customFields: [] } };
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].templateOptions.options = AdminFactory.taskTypes;
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].defaultValue = node == undefined ? null : node;
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[6].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[6].templateOptions.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[7].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[7].templateOptions.options = GlobalDataFactory.taskOrigins;

            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].templateOptions.onSelected = function(type){
            	taskTypeChangeProfiles(type);

        		var customFieldsTaskType = $linq(modal.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.customFields).where("x => !x.disabled").orderBy("x => x.viewOrder").toArray();
            	var customFieldsParentTaskType = [];
        		if(type){
        			modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[9].data.type.parent = type;
            		
                    type.customFields = $linq(type.customFields).orderBy("x => x.viewOrder").toArray();
                    angular.forEach(type.customFields, function (value, key) {
                        var customField = {
                            customField: value.customField,
                            id: value.id,
                            delete: value.deleted,
                            viewOrder:value.viewOrder,
                            required: value.required,
                            noEditable: value.noEditable,
                            taskType: value.taskType,
                            createdDate: value.createdDate,
                            value: value.value
                        };
                        customField.requiredString = 'OPTIONAL';
                        if(value.required){
                            customField.requiredString = 'REQUIRED';
                        }else if(value.noEditable) {
                            customField.requiredString = 'NO_EDITABLE';
                        }
                        //deshabilitem l'element per a que no pugui editar el customField del pare
                        customField.disabled = true;
                        customField.customField.disableElement = true;
                        
                        customFieldsParentTaskType.push(customField);
                    });
        		} else {
        			modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[9].data.type.parent = undefined;
        		}
    			modal.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.customFields = customFieldsTaskType.concat(customFieldsParentTaskType);
        		
        	}
            
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].data.clear = function ($event, model, key, $select, to) {
                $event.stopPropagation();
                model[key] = undefined;
                if ($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
                if(to.onSelected) {
                    to.onSelected(undefined);
                }
            }

            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[4].templateOptions.onClick = function($viewValue, $modelValue, scope){
            	var parentTaskType = undefined;
            	
                if(scope.model.parent instanceof Object) {
                	parentTaskType = scope.model.parent;
                } else {
                	parentTaskType = $linq(scope.fields[3].templateOptions.options).where("x => x.id == " + scope.model.parent).toArray();
                }

                if (parentTaskType && $viewValue) {
                	taskTypeChangeProfiles(parentTaskType);
                	scope.fields[5].hide = false;
                } else {
                	scope.fields[5].hide = true;
                }
            };

            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[4].hideExpression = function($viewValue, $modelValue, scope) {
        		if(scope.model && scope.model.parent){
        			return hideFunction(scope.model.parent, scope.fields[3].templateOptions.options);
        		} else {
        			return true;
        		}
            }

            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[5].hideExpression = function($viewValue, $modelValue, scope) {
        		if(scope.model && scope.model.parent && scope.model.inheritProfiles){
        			return hideFunction(scope.model.parent, scope.fields[3].templateOptions.options);
        		} else {
        			return true;
        		}
            }
            
        	var hideFunction = function(modelParent, list) {
            	var hide = true;
            	var parentId = undefined;
            	
                if(modelParent instanceof Object) {
                	parentId = modelParent.id;
                } else {
                	parentId = modelParent;
                }

            	if (parentId) {
                	var parent = $linq(list).firstOrDefault(undefined, "x => x.id == " + parentId);
                	if (parent && parent.profiles.length > 0) {
                		hide = false;
                	}
            	}
                return hide;
            }

            var taskTypeChangeProfiles = function (taskType) {
            	var parentProfiles = [];
            	if (taskType) {
                	var profilesParentTaskType = $linq(taskType).select("x => x.profiles").toArray();

        			angular.forEach(profilesParentTaskType[0], function (value, key) {
        				parentProfiles.push(value.profile);
                    });
            	}
            	
            	if(parentProfiles && parentProfiles.length > 0 && modal.annexaFormly.model.modal_body.column1.inheritProfiles){
            		modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[5].data.value = '';
            		//Aquí haurem de posar els perfils de la tasca pare seleccionada
            		angular.forEach(parentProfiles, function (value, key) {
            			modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[5].data.value += value[Language.getActiveColumn()] + ' | ';
                    });
            		modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[5].data.value = 
            			modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[5].data.value.substring(0, modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[5].data.value.lastIndexOf(' | '));
            	} else {
            		//netejar el camp
            		modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[5].data.value = '';
            	}
            	
            }
            
            if (node && !node.pageX) {
            	modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].templateOptions.onSelected(node);
            } else {
            	modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].templateOptions.onSelected();
            }
            
            AnnexaFormlyFactory.showModal('modalNewTaskType', modal, $scope.saveTaskType, false);
        };

    	$scope.customFields = [];
        CustomFieldFactory.getCustomFields().then(function (data) {
            if (data){
                var response = JSOG.decode(data);
                $scope.customFields.length = 0;
                $scope.customFields.push({id:"-1", value: '', language1:$filter('translate')('global.literals.new'), language2:$filter('translate')('global.literals.new'), language3:$filter('translate')('global.literals.new')});
                response.customFields = $linq(response.customFields).orderBy("x => x." + $scope.languageColumn, linq.caseInsensitiveComparer).toArray();
                angular.forEach(response.customFields, function(value, key){
                    var aux = HelperService.findFirstElementWhere(response.customFieldsDisable, 'id', value.id, -1);
                    if(aux != -1){
                        value.disableElement = true;
                    }
                    $scope.customFields.push(value);
                });
            }
        });

        $scope.statuses = [];

        $rootScope.$on('AnnexaTaskTypeStatusAdded', function(event, args) {
            $scope.statuses = [];

            _.forEach(args.statuses, function (value) {
                $scope.statuses.push(value);
            });
        });

        $rootScope.$on('AnnexaTaskTypeStatusDeleted', function(event, args) {
        	var taskType = args.item.type;
        	if (taskType) {
                var ttsIndex = $linq(taskType.statuses).indexOf("x => x.id == " + args.item.id);
				if (ttsIndex != -1) {
					taskType.statuses.splice(ttsIndex, 1);
				}
        		$scope.updateTaskType(taskType);
        	}
        });
        
        $rootScope.$on('AnnexaTaskTypeStatusUpdated', function(event, args) {
        	RestService.findOne('TaskType', args.status.type.id)
            .then(function(data) {
                var decodedData = JSOG.decode(data.data);
                
            	var taskType = decodedData;
                if(args.status && (args.status.language1 || args.status.language2 || args.status.language3)) {
                    var update = {
                        createdDate: args.status.createdDate,
                        deleted: args.status.deleted,
                        id: args.status.id,
                        revision: args.status.revision,
                        language1: args.status.language1 ? args.status.language1 : 'NotTranslated',
                        language2: args.status.language2 ? args.status.language2 : 'NotTranslated',
                        language3: args.status.language3 ? args.status.language3 : 'NotTranslated',
                        acronym: args.status.acronym,
                        user: args.status.user,
                        profile: args.status.profile,
                        globalStatus: args.status.globalStatus,
                        defaultValue: args.status.defaultValue,
                        sendMail: args.status.sendMail,
                        orderView: args.status.orderView
                    };
                	update.type = { id: taskType.id };

                	if (!update.id) {
                    	var taskTypeStatus = $linq(taskType.statuses).firstOrDefault(undefined, "x => x.orderView == " + update.orderView);
                    	if (taskTypeStatus && taskTypeStatus.id) {
                    		update.createdDate = taskTypeStatus.createdDate;
                    		update.deleted = taskTypeStatus.deleted;
                    		update.id = taskTypeStatus.id;
                    		update.revision = taskTypeStatus.revision;
                    	}
                	}

                	if (update.defaultValue) {
                    	var defaultStatus = $linq(taskType.statuses).firstOrDefault(undefined, "x => x.defaultValue == true && x.orderView != " + update.orderView);
                		if (defaultStatus) {
                			defaultStatus.defaultValue = false;
                		}
                	}
                	
                    if(update.id) {
                        RestService.update('./api/task_type_status/' + update.id, update)
                            .then(function (data) {
                                var ttsIndex = $linq(taskType.statuses).indexOf("x => x.id == " + data.id);
            					if (ttsIndex != -1) {
            						taskType.statuses.splice(ttsIndex, 1, data);
            					}
                            	$scope.updateTaskType(taskType);
                            }).catch(function () {
                            //Empty
                        });
                    } else {
                        RestService.insert('./api/task_type_status', update)
                            .then(function(data) {
                            	_.forEach(taskType.statuses, function(value, key) {
                                    if(!value.id) {
                                    	taskType.statuses.splice(key, 1);
                                    }
                                });
                            	taskType.statuses.push(data);
                            	$scope.updateTaskType(taskType);
                            }).catch(function() {
                            //Empty
                        });
                    }
                }
            });
        });

        $rootScope.$on('AnnexaTaskTypeCustomFieldUpdated', function(event, args) {
        	var taskType = args.item.taskType;
        	if (taskType) {
        		$scope.updateTaskType(taskType);
        	}
        });
        
        $rootScope.$on('AnnexaTaskTypeCustomFieldDeleted', function(event, args) {
        	var taskType = args.item.taskType;
        	if (taskType) {
                var ttCFIndex = $linq(taskType.customFields).indexOf("x => x.id == " + args.item.id);
				if (ttCFIndex != -1) {
					taskType.customFields.splice(ttCFIndex, 1);
				}
        		$scope.updateTaskType(taskType);
        	}
        });
        
        $scope.saveTaskType = function (taskType) {
            var modal = this;

            var profiles = [];
            _.forEach(taskType.annexaFormly.model.modal_body.column1.profiles, function(item) {
                profiles.push({ profile: { id: item } });
            });

            var model = {
                language1: taskType.annexaFormly.model.modal_body.column1.language1,
                language2: taskType.annexaFormly.model.modal_body.column1.language2 ? taskType.annexaFormly.model.modal_body.column1.language2 : 'NotTranslated',
                language3: taskType.annexaFormly.model.modal_body.column1.language3 ? taskType.annexaFormly.model.modal_body.column1.language3 : 'NotTranslated',
                acronym: taskType.annexaFormly.model.modal_body.column1.acronym,
                abstractTaskType: taskType.annexaFormly.model.modal_body.column1.abstractTaskType,
                inheritProfiles: taskType.annexaFormly.model.modal_body.column1.inheritProfiles ? taskType.annexaFormly.model.modal_body.column1.inheritProfiles : false,
                customFields: [],
                profiles: profiles,
                origin: taskType.annexaFormly.model.modal_body.column1.origin ? { id: taskType.annexaFormly.model.modal_body.column1.origin } : undefined,
                allowedCreateExternally: taskType.annexaFormly.model.modal_body.column1.allowedCreateExternally ? taskType.annexaFormly.model.modal_body.column1.allowedCreateExternally : false,
                statuses: $scope.statuses
            }

            if (taskType.annexaFormly.model.modal_body.column1.parent) {
                if(taskType.annexaFormly.model.modal_body.column1.parent instanceof Object) {
                    model.parent = taskType.annexaFormly.model.modal_body.column1.parent;
                } else {
                    model.parent = {id: taskType.annexaFormly.model.modal_body.column1.parent};
                }
            } else {
            	model.parent = undefined;
            }

            angular.forEach(this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.customFields, function (value, key) {
                if (!value.disabled) {
	                var field = {
	                    customField: value.customField,
	                    required: value.requiredString == 'REQUIRED',
	                    noEditable: value.requiredString == 'NO_EDITABLE',
	                    viewOrder:value.viewOrder,
	                    value: value.value
	                };
                	model.customFields.push(field);
                }
            });

            RestService.insert('./api/task_type', model)
                .then(function (data) {
                	AdminFactory.taskTypes.push(data);
                	$scope.taskTypes = CommonService.getNestableData(AdminFactory.taskTypes, 'taskType', 'orderView');
                    if(GlobalDataFactory.taskTypes){
                    	GlobalDataFactory.taskTypes.length = 0;
                    }else{
                    	GlobalDataFactory.taskTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('TaskType')){
                    	CacheFactory.get('globalDataCache').remove('TaskType');
                    }
                    GlobalDataFactory.initializeTaskLists();
                	$scope.statuses = [];
                    modal.close();
                }).catch(function () {
                    modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') })
            })
        }

        $scope.viewTaskType = function (taskType) {
        	var customFieldsType = [];
        	var modal = angular.copy(CommonAdminModals.taskTypeEdit);
            modal.alerts = [];
            modal.data = angular.copy(taskType);
            modal.languageColumn = Language.getActiveColumn();

            modal.printAbstractTaskType = function (model) {
                if(model) {
                    return $filter('translate')('global.commonAdmin.modal.taskType.abstractTaskType');
                } else {
                    return $filter('translate')('global.commonAdmin.modal.taskType.notAbstractTaskType');
                }
            }

            modal.taskTypes = $linq(AdminFactory.taskTypes).where("x => x.id != " + taskType.id).toArray();
            
            modal.printInheritParentTaskTypeProfiles = function (model) {
                if(model) {
                    return $filter('translate')('global.commonAdmin.modal.taskType.inheritProfilesParentTaskType');
                } else {
                    return $filter('translate')('global.commonAdmin.modal.taskType.notInheritProfilesParentTaskType');
                }
            }
            
            modal.data.profiles.profile = $linq(modal.data.profiles).select("x => x.profile").toArray();

            modal.printProfiles = function (model) {
                var perfiles = '';
                angular.forEach(model, function (val, key) {
                    if(perfiles != '') {
                        perfiles += ', ';
                    }
                    perfiles += val[modal.languageColumn];
                });

                return perfiles;
            };
            
            modal.taskOrigins = angular.copy(GlobalDataFactory.taskOrigins);
            modal.taskOrigins.unshift({id:undefined, language1:$filter('translate')('global.literals.empty'), language2:$filter('translate')('global.literals.empty'), language3:$filter('translate')('global.literals.empty')});
            
            modal.printAllowedCreateExternally = function (model) {
            	if(model) {
                    return $filter('translate')('global.commonAdmin.modal.roleInterested.yes');
                } else {
                    return $filter('translate')('global.commonAdmin.modal.roleInterested.no');
                }
            }
            
            modal.parentChange = function (parent) {
            	var parentProfiles = [];
            	if (parent) {
                	var profilesParentTaskType = $linq(parent).select("x => x.profiles").toArray();

        			angular.forEach(profilesParentTaskType[0], function (value, key) {
        				parentProfiles.push(value.profile);
                    });
            	}
            	
            	if(parentProfiles && parentProfiles.length > 0 && modal.data.inheritProfiles){
            		modal.parentProfiles = '';
            		//Aquí haurem de posar els perfils de la tasca pare seleccionada
            		angular.forEach(parentProfiles, function (value, key) {
            			modal.parentProfiles += value[Language.getActiveColumn()] + ' | ';
                    });
            		modal.parentProfiles = modal.parentProfiles.substring(0, modal.parentProfiles.lastIndexOf(' | '));
            	} else {
            		//netejar el camp
            		modal.parentProfiles = '';
            	}
            	
                parent.customFields = $linq(parent.customFields).orderBy("x => x.viewOrder").toArray();
                angular.forEach(parent.customFields, function (value, key) {
                    var customField = {
                        customField: value.customField,
                        id: value.id,
                        delete: value.deleted,
                        viewOrder:value.viewOrder,
                        required: value.required,
                        noEditable: value.noEditable,
                        taskType: value.taskType,
                        createdDate: value.createdDate,
                        value: value.value
                    };
                    customField.requiredString = 'OPTIONAL';
                    if(value.required){
                        customField.requiredString = 'REQUIRED';
                    }else if(value.noEditable) {
                        customField.requiredString = 'NO_EDITABLE';
                    }
                    //deshabilitem l'element per a que no pugui editar el customField del pare
                    customField.disabled = true;
                    customField.customField.disableElement = true;
                    
                    customFieldsType.push(customField);
                });
            }
            
            modal.setCustomFieldsTaskType = function (customFields) {
                var cFs = $linq(customFields).orderBy("x => x.viewOrder").toArray();
                angular.forEach(cFs, function (value, key) {
                    var customField = {
                        customField: value.customField,
                        id: value.id,
                        delete: value.deleted,
                        viewOrder:value.viewOrder,
                        required: value.required,
                        noEditable: value.noEditable,
                        taskType: value.taskType,
                        createdDate: value.createdDate,
                        value: value.value
                    };
                    customField.requiredString = 'OPTIONAL';
                    if(value.required){
                        customField.requiredString = 'REQUIRED';
                    }else if(value.noEditable) {
                        customField.requiredString = 'NO_EDITABLE';
                    }
                    var aux = HelperService.findFirstElementWhere($scope.customFields, 'id', value.customField.id, -1);
                    if(aux != -1 && aux.disableElement){
                        customField.customField.disableElement = true;
                    }
                    customFieldsType.push(customField);
                });
            }
            
            modal.parentProfiles = '';
            
            if (modal.data.parent) {
            	modal.parentChange(modal.data.parent);
            }
            modal.setCustomFieldsTaskType(modal.data.customFields);

            $scope.$on('AnnexaParentTaskTypeChange', function(event, args){
            	if(args && args.item){
            		customFieldsType = [];
            		modal.data.inheritProfiles = args.item.inheritProfiles;
            		modal.parentProfiles = '';
                    if (args.item.parent) {
                    	modal.parentChange(args.item.parent);
                    }
                    modal.setCustomFieldsTaskType(args.item.customFields);
        		}
                modal.customFieldsType = customFieldsType;
            });
            
            var selectableOptions = [];
            _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                var haveProfile = false;
                if(modal.data.profiles) {
                    haveProfile = modal.data.profiles.some(function (profile) {
                          return profile.id === value.id;
                        });
                }
                if(value.expiryDate == null || haveProfile) {
                    selectableOptions.push(value);
                }
            });

            modal.profiles = selectableOptions;
            modal.updateTaskType = $scope.updateTaskType;
            modal.customFieldsType = customFieldsType;

            AnnexaModalFactory.showModal('modaTaskTypeEdit', modal);
        }
        
        $scope.updateTaskType = function(val, prop) {
            var model = angular.copy(this.data);

            if (prop == 'profiles') {
            	model[prop] = [];
                _.forEach(val, function (item) {
                	model[prop].push({profile: {id: item.id}});
                });
            } else if (prop == 'acronym' || prop == 'inheritProfiles' || prop == 'abstractTaskType' || prop == 'allowedCreateExternally') {
                model[prop] = val;
            } else if (prop == 'origin') {
            	model[prop] = (val && val.id != undefined) ? { id: val.id } : undefined;
            } else if (prop == 'parent') {
            	if (val.id != -1) {
            		model.parent = val;
            		if (val.profiles.length == 0) {
            			model.inheritProfiles = false;
            		}
            	} else {
            		model.parent = undefined;
            		model.inheritProfiles = false;
            	}
            } else {
                model = val;
            }
            
            RestService.update('./api/task_type/' + model.id, model)
                .then(function (data) {
                    var taskTypeIndex = $linq(AdminFactory.taskTypes).indexOf("x => x.id == " + data.id);
					if (taskTypeIndex != -1) {
						AdminFactory.taskTypes.splice(taskTypeIndex, 1, data);
					}
                	$scope.taskTypes = CommonService.getNestableData(AdminFactory.taskTypes, 'taskType', 'orderView');
                    if(GlobalDataFactory.taskTypes){
                    	GlobalDataFactory.taskTypes.length = 0;
                    }else{
                    	GlobalDataFactory.taskTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('TaskType')){
                    	CacheFactory.get('globalDataCache').remove('TaskType');
                    }
                    GlobalDataFactory.initializeTaskLists();
                    $scope.$broadcast('AnnexaParentTaskTypeChange', { item: data });
                }).catch(function () {
                    //Empty
            });
        }
        
        $scope.deleteTaskType = function(taskType) {
            var dlg = dialogs.confirm(
                $filter('translate')('global.commonAdmin.literals.deleteTaskType'),
                $filter('translate')('global.commonAdmin.literals.confirmDeleteTaskTypeMessage'),
                {
                    animation: true,
                    backdrop: 'static',
                    keyboard: true,
                    size: ''
                }
            );

            dlg.result.then(function (btn) {
            	RestService.delete('./api/task_type/' + taskType.id)
                    .then(function (data) {
	                    var taskTypeIndex = $linq(AdminFactory.taskTypes).indexOf("x => x.id == " + taskType.id);
						if (taskTypeIndex != -1) {
							AdminFactory.taskTypes.splice(taskTypeIndex, 1);
						}
                    	$scope.taskTypes = CommonService.getNestableData(AdminFactory.taskTypes, 'taskType', 'orderView');
                        if(GlobalDataFactory.taskTypes){
                        	GlobalDataFactory.taskTypes.length = 0;
                        }else{
                        	GlobalDataFactory.taskTypes = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('TaskType')){
                        	CacheFactory.get('globalDataCache').remove('TaskType');
                        }
                        GlobalDataFactory.initializeTaskLists();
                    }).catch(function (error) {
	                    var err = dialogs.error(
	                        $filter('translate')('DIALOGS_ERROR_MSG'),
	                        $filter('translate')('global.errors.unknownDelete'),
	                        {
	                            animation: true,
	                            backdrop: 'static',
	                            keyboard: true,
	                            size: ''
	                        }
	                    );
                 });
            }, function (btn) {
            });
        }

        $scope.treeOptions = {};

        $scope.exportTaskTypes = function(){
            $rootScope.showLoadingdivSignin = true;
            try {
                var cells = [$scope.langColumn];
                var fileName = "taskTypes.xls";
                var xlsFile = HelperService.exportToExcel($scope.taskTypes, 'nodes', cells, 'taskType', fileName, window, 'global.commonAdmin.tabs.taskTypes');
                $rootScope.showLoadingdivSignin = false;
            } catch(e) {
                $rootScope.showLoadingdivSignin = false;
                console.log(e.message);
            }
        }
    }])
    .controller('TaskOriginController',['$scope', '$rootScope', '$state', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'DTColumnBuilder', 'HeaderService', 'TableFilter',
    		function($scope, $rootScope, $state, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, DTColumnBuilder, HeaderService, TableFilter) {
    	$rootScope.loading(false);
    	$scope.languageColumn = Language.getActiveColumn();
        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');
        $scope.dtDefTaskOrigin = new TableData('tasks', 'TaskOrigin', './api/tasks/byfilter').setSort([[1, 'asc']]).setSortName($scope.languageColumn);

        var NameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', $scope.languageColumn);
        var TaskOriginsActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
            new ActionButton('global.literals.see', 'openTaskOrigin([data])', 'fa-eye'),
            [new ActionButton('global.literals.remove', 'deleteTaskOrigin([data])')]);
        
        $scope.tableTaskOrigin = {
            dtColumns: [
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn(NameColumn.getColumn()).withTitle(NameColumn.getTitle()).renderWith(NameColumn.getRender),
                DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(TaskOriginsActionsColumn.getTitle()).renderWith(TaskOriginsActionsColumn.getRender).notSortable()
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn},
            reloadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = $scope.tfilter.getFilterCallAux();
                this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                this.filterCallAux.langColumn = $scope.languageColumn;
                this.table.reloadData(function callbackInstance(json) {}, resetPaging);
            }
        };

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.task_origin') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-taskOrigin','fa fa-plus','global.commonAdmin.modal.taskOrigin.titleNew',undefined,'openNewTaskOrigin')
                ];
            }
        });


        HeaderService.changeState($state.current);

        $scope.doSearchFilter=function(queued) {
            $scope.tableTaskOrigin.reloadInternalData(true);
        };

    	$scope.openNewTaskOrigin = function() {
            var modal = angular.copy(CommonAdminModals.taskOriginNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewTaskOrigin', modal, $scope.saveTaskOrigin, false);
        }

        $scope.saveTaskOrigin = function (taskOrigin) {
            var modal = this;

            var model = {
                language1: taskOrigin.annexaFormly.model.modal_body.language1,
                language2: taskOrigin.annexaFormly.model.modal_body.language2 ? taskOrigin.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: taskOrigin.annexaFormly.model.modal_body.language3 ? taskOrigin.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                acronym: taskOrigin.annexaFormly.model.modal_body.acronym
            }

            RestService.insert('./api/task_origin', model)
                .then(function () {
                	$scope.tableTaskOrigin.reloadInternalData(false);
                    if(GlobalDataFactory.taskOrigins){
                    	GlobalDataFactory.taskOrigins.length = 0;
                    }else{
                    	GlobalDataFactory.taskOrigins = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('TaskOrigin')){
                    	CacheFactory.get('globalDataCache').remove('TaskOrigin');
                    }
                    GlobalDataFactory.initializeTaskLists();
                    modal.close();
                }).catch(function () {
                    modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') })
            })
        }

        $scope.openTaskOrigin = function (id) {
            RestService.findOne('TaskOrigin', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = CommonAdminModals.taskOriginEdit;
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = $scope.languageColumn;

                    modal.updateTaskOrigin = $scope.updateTaskOrigin;

                    AnnexaModalFactory.showModal('modalTaskOriginEdit', modal);
                });
        }

        $scope.updateTaskOrigin = function(val, prop) {
            var model = angular.copy(this.data);

            if (prop == 'acronym') {
                model[prop] = val;
            } else {
                model = val;
            }
            
            RestService.update('./api/task_origin/' + model.id, model)
                .then(function (data) {
                	$scope.tableTaskOrigin.reloadInternalData(false);
                    if(GlobalDataFactory.taskOrigins){
                    	GlobalDataFactory.taskOrigins.length = 0;
                    }else{
                    	GlobalDataFactory.taskOrigins = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('TaskOrigin')){
                    	CacheFactory.get('globalDataCache').remove('TaskOrigin');
                    }
                    GlobalDataFactory.initializeTaskLists();
                }).catch(function () {
                    //Empty
            });
        }

        $scope.deleteTaskOrigin = function(id) {
            if(id) {
                RestService.checkDelete('./api/task_origin/check_delete/' + id)
                    .then(function(data) {
                        if(data) {
                            RestService.delete('./api/task_origin/' + id)
                                .then(function(data) {
                                	if(GlobalDataFactory.taskOrigins){
                                    	GlobalDataFactory.taskOrigins.length = 0;
                                    }else{
                                    	GlobalDataFactory.taskOrigins = [];
                                    }
                                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('TaskOrigin')){
                                    	CacheFactory.get('globalDataCache').remove('TaskOrigin')
                                    }
                                    GlobalDataFactory.initializeTaskLists();
                                    $scope.doSearchFilter();
                                }).catch(function (error) {
                                    console.log(error);
                            })
                        }
                    }).catch(function(error) {
                    	console.log(error);
                });

            }
        };

    }])