angular
    .module('annexaApp')
	.controller('PredefinedFiltersController',['$scope', '$rootScope', '$q', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory', 'AdminFactory', 'CommonService',
    	function($scope, $rootScope, $q, CommonAdminModals, AnnexaFormlyFactory, RestService, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory, AdminFactory,CommonService) {

		$scope.additionalFilter = {};
		$scope.modules = undefined;
		$scope.tables = [];
		var reloadModules = function(){
			if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.filters_configuration && $rootScope.app.configuration.filters_configuration.filtersConfiguration && $rootScope.app.configuration.filters_configuration.filtersConfiguration.modules){
				$scope.modules = angular.copy($rootScope.app.configuration.filters_configuration.filtersConfiguration.modules);
				_.forEach($scope.modules, function(module){
					if(module && module.filters && module.filters.length > 0){
						_.forEach(module.filters, function(table){
							var tableAux = angular.copy(table);
							tableAux.module = module.name; 
							$scope.tables.push(tableAux);						
						});
					}
				});
			}
		}
        
		reloadModules();
		
		var getFilterCall = function(){
            return {};
        }
        var getFilterCallAux = function(){
            return $scope.additionalFilter;
        }
 		
		var renderModuleColumn = function (data, type, full, meta){
			var content = '';
		    if(data){
		        content = $filter('translate')('global.predefinedFields.modules.'+data);
		    }
            return content;
        }
        
		var renderTableColumn = function (data, type, full, meta){
			var content = '';
		    if(data){
		        content = $filter('translate')('global.predefinedFields.tables.'+data);
		    }
            return content;
        }
          
 		var actionsColumnRender = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','viewElement(\'[data]\', \'[data2]\')','fa-eye',false),undefined, 'module');
		
		$scope.filter = [
	       	//{ id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name'},
        	//{ id: 'territorialGroupType', type: 'text', order: 1, label: 'global.thirds.literals.territorialGroupType', callAux: true }
        ];

		$scope.columns = [
			{id: 'module', title: $filter('translate')('global.literals.module'), sortable: false, render: renderModuleColumn},
			{id: 'table', title: $filter('translate')('global.predefinedFields.table'), sortable: false, render: renderTableColumn},
		    {id: 'actions', columnName: 'table', width: '150px', className: 'text-center', column: actionsColumnRender,  sortable: false}
        ];

		$scope.tableDefinition = {
            id: 'tableExecuteQuery',
            sort: [[0, 'asc']],
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: $scope.columns,
            containerScope: $scope,
            objectsPaginated: function(pag, size){
				var deferred = $q.defer();
				if($scope.modules && $scope.tables && $scope.tables.length > 0){
					var totalPages = Math.floor($scope.tables.length / size);
					var tablesAux = [];
					for(i = (pag * size); (i<((pag+1) * size) && i<$scope.tables.length); i++){
						if($scope.tables[i]){
							tablesAux.push(angular.copy($scope.tables[i]));
						}
					}
					
					deferred.resolve({
					    "content": tablesAux,
					    "last": ((pag && pag === totalPages)?true:false),
					    "totalElements": $scope.tables.length,
					    "totalPages": totalPages,
					    "size": size,
					    "number": 0,
					    "first": ((pag && pag === 0)?true:false),
					    "sort": null,
					    "numberOfElements": tablesAux.length
					});
				}else{
					deferred.resolve({
					    "content": [],
					    "last": true,
					    "totalElements": 0,
					    "totalPages": 0,
					    "size": size,
					    "number": 0,
					    "first": true,
					    "sort": null,
					    "numberOfElements": 0
					});
				}
				return deferred.promise;
                
            }
        }

 		$scope.$on('executeQueryResultErrorComponent',function(event,args){
            $scope.tableDefinition.reloadInternalData(true, true);
        });
		
        $scope.viewElement = function (table, module) {
			if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.filters_configuration && $rootScope.app.configuration.filters_configuration.filtersConfiguration && $rootScope.app.configuration.filters_configuration.filtersConfiguration.modules){
				var predef = angular.copy($rootScope.app.configuration.filters_configuration.filtersConfiguration.modules);
				var moduleAux = $linq(predef).firstOrDefault(undefined,"x => x.name == '"+module+"'");
				if(moduleAux && moduleAux.filters && moduleAux.filters.length > 0){
					var tableAux = $linq(moduleAux.filters).firstOrDefault(undefined,"x => x.table == '"+table+"'");
					if(tableAux && tableAux.filters && tableAux.filters.length > 0){
						var language = Language.getActiveColumn();
						var fields = [];
	            		var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
		                var modal = angular.copy(CommonAdminModals.updatePredefinedFilterModal);
		                modal.annexaFormly = new AnnexaFormly();
		                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
		                modal.annexaFormly.dataAux = {module:module, table:table};
						modal.annexaFormly.model = {};
						modal.annexaFormly.model.modal_body = {};
		                modal.annexaFormly.options = {};
		                modal.annexaFormly.options.formState = {readOnly: false};
		                modal.alerts = [];
						_.forEach(tableAux.filters, function(filter){
							if(!filter.esetValidation || filter.esetValidation === "false" || (filter.esetValidation && filter.esetValidation === "true" && ((filter.onlyEset === "true" && $rootScope.esetMode) || (filter.onlyEset === "false" && !$rootScope.esetMode)))){
								if(filter.type === 'text' && filter.name){
									modal.annexaFormly.model.modal_body[filter.name] = ((filter.value)?filter.value:undefined);
									var field = modal.annexaFormly.createField(filter.name,'annexaInputRow','col-sm-5',new AnnexaFormlyFieldTemplateOptions('text',filter.label,false,false,undefined,255),data);                
									fields.push(field);
									modal.annexaFormly.model.modal_body[filter.name+'_negated'] = ((filter.negated === 'true')?true:false);
									var field = modal.annexaFormly.createField(filter.name+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);							
								}else if(filter.type === 'checkbox' && filter.name){
									modal.annexaFormly.model.modal_body[filter.name] = ((filter.value === 'true' || filter.value === true)?true:undefined);
									var field = modal.annexaFormly.createField(filter.name,'annexaCheckbox','col-sm-6 p-t',{type: 'checkbox', label: filter.label, required:false},data);                
									fields.push(field);								
								}else if(filter.type === 'dateRange' && filter.name){
									var dates = undefined;
									if(filter.value){
										try{
											dates = filter.value.split(';');
										}catch(e){
											dates = undefined;
										}
										var isDateTo = false;
										if(dates && dates.length > 1 && dates[1]) {
											var dateAux = dates[1];
											dateAux = dateAux.split('/');
											if(dates[0] == 'dataFrom'){
												if(dateAux && dateAux.length == 3){
					                            	modal.annexaFormly.model.modal_body[filter.name+'From'] = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],00,00,00));
												}else{
													modal.annexaFormly.model.modal_body[filter.name+'From'] = undefined;
												}
											}else{
												isDateTo = true;
												if(dateAux && dateAux.length == 3){
													modal.annexaFormly.model.modal_body[filter.name+'To'] = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],21,59,59));
												}else{
													modal.annexaFormly.model.modal_body[filter.name+'To'] = undefined;
												}	
											}
				                        }else{
											modal.annexaFormly.model.modal_body[filter.name+'From'] = undefined;
										}
				
				                        if(dates && dates.length > 3 && dates[3]) {
				                            var dateAux = dates[3];
											dateAux = dateAux.split('/');
				                            if(dateAux && dateAux.length == 3){
												modal.annexaFormly.model.modal_body[filter.name+'To'] = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],21,59,59));
											}else{
												modal.annexaFormly.model.modal_body[filter.name+'To'] = undefined;
											}
				                        }else{
											if(!isDateTo){
												modal.annexaFormly.model.modal_body[filter.name+'To'] = undefined;	
											}
										}
									}else{
										modal.annexaFormly.model.modal_body[filter.name+'From'] = undefined;
										modal.annexaFormly.model.modal_body[filter.name+'To'] = undefined;
									}
									var field = modal.annexaFormly.createField(filter.name+'From','annexaDatepickerRow','col-sm-3',new AnnexaFormlyFieldDatepickerTemplateOptions('text',filter.label,false,{format: 'dd/MM/yyyy',initDate:undefined,showWeeks: false,startingDay: 1}),data);                
									fields.push(field);
									field = modal.annexaFormly.createField(filter.name+'To','annexaDatepickerRow','col-sm-2',new AnnexaFormlyFieldDatepickerTemplateOptions('text','global.literals.dateTo',false,{format: 'dd/MM/yyyy',initDate:undefined,showWeeks: false,startingDay: 1}),data);                
									fields.push(field);
									modal.annexaFormly.model.modal_body[filter.name+'_negated'] = ((filter.negated === 'true')?true:false);
									var field = modal.annexaFormly.createField(filter.name+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);								
								}else if(filter.type === 'select' && filter.name){
									var list = [];
									if(filter.factoryArray){
										var factory = angular.element(document.body).injector().get(filter.factoryArray);
										if(factory && factory[filter.nameArray]){
											list = angular.copy(factory[filter.nameArray]);
											var selected = $linq(factory[filter.nameArray]).firstOrDefault(undefined,"x => x.id+'' == '"+filter.value+"'");
											if(selected && filter.literalArray && filter.literalArray != 'id' && filter.literalArray != 'language'){
												selected[filter.literalArray] = $filter('translate')(selected[filter.literalArray]);
											}
											modal.annexaFormly.model.modal_body[filter.name] = ((selected)?selected:undefined);									
										}
									}
									var field = modal.annexaFormly.createField(filter.name, 'annexaSelectRow', 'col-sm-5', new AnnexaFormlyFieldSelectTemplateOptions(filter.label,'id',((filter.literalArray)?((filter.literalArray === 'language')?language:filter.literalArray):'id'),list,false),data);
									fields.push(field);
									modal.annexaFormly.model.modal_body[filter.name+'_negated'] = ((filter.negated === 'true')?true:false);
									var field = modal.annexaFormly.createField(filter.name+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);								
								}else if(filter.type === 'select-multiple' && filter.name){
									var list = [];
									if(filter.factoryArray){
										var factory = angular.element(document.body).injector().get(filter.factoryArray);
										if(factory && factory[filter.nameArray]){
											list = angular.copy(factory[filter.nameArray]);
											var selected = [];
											try{
												if(filter.value){
													var selectedList = filter.value.split(';');
													if(selectedList && selectedList.length > 0){
														selected =  $linq(list).intersect(selectedList, function(x,y){
															if(y && (y.id || y.id === false) && (y.id+"") == x ){
																return true;
															}else{
																return false;
															}
														}).toArray(); 
													}
												}
											}catch(e){}
											if(selected && selected.length > 0){
												_.forEach(selected, function(sel){
													if(filter.literalArray && filter.literalArray != 'id' && filter.literalArray != 'language'){
														sel[filter.literalArray] = $filter('translate')(sel[filter.literalArray]);
													}
												});
											}
											modal.annexaFormly.model.modal_body[filter.name] = ((selected)?selected:undefined);									
										}
									}
									var field = modal.annexaFormly.createField(filter.name, 'annexaMultipleSelectRow', 'col-sm-5', new AnnexaFormlyFieldSelectTemplateOptions(filter.label, 'id',((filter.literalArray)?((filter.literalArray === 'language')?language:filter.literalArray):'id'), list, false),data);
									fields.push(field);		
									modal.annexaFormly.model.modal_body[filter.name+'_negated'] = ((filter.negated === 'true')?true:false);
									var field = modal.annexaFormly.createField(filter.name+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);
								}else if(filter.type === 'select-tree' && filter.name){
									var list = [];
									if(filter.factoryArray){
										var factory = angular.element(document.body).injector().get(filter.factoryArray);
										if(factory && factory[filter.nameArray]){
											list =  CommonService.getTreeData(angular.copy(factory[filter.nameArray]), ((filter.literalArray)?((filter.literalArray === 'language')?language:filter.literalArray):'id'));
											var selected = $linq(factory[filter.nameArray]).firstOrDefault(undefined,"x => x.id+'' == '"+filter.value+"'");
											if(selected && filter.literalArray && filter.literalArray != 'id' && filter.literalArray != 'language'){
												selected[filter.literalArray] = $filter('translate')(selected[filter.literalArray]);
											}
											
											var title = ((filter.literalArray)?((filter.literalArray === 'language')?language:filter.literalArray):'id');
											title = ((selected && selected[title])?selected[title]:'id');
											modal.annexaFormly.model.modal_body[filter.name] = {$selected:((selected)?{id:selected.id,title:title}:undefined)};									
										}
									}
									var field = modal.annexaFormly.createField(filter.name, 'annexaSelectTreeRow', 'col-sm-5', new AnnexaFormlyFieldSelectTemplateOptions(filter.label,'id',((filter.literalArray)?((filter.literalArray === 'language')?language:filter.literalArray):'id'),list,false, undefined, undefined, undefined, true),data);
									fields.push(field);
									modal.annexaFormly.model.modal_body[filter.name+'_negated'] = ((filter.negated === 'true')?true:false);
									var field = modal.annexaFormly.createField(filter.name+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);								
								}
							} 
						});
						AnnexaFormlyFactory.showModal("modalUpdatePredefinedFilterModal", modal, $scope.updatePredefinedFilters, false);
					}	
				}
				
			}
        }

        $scope.updatePredefinedFilters = function(val, prop) {
        	var self = this;
			var module = undefined;
			var table = undefined;
			var model = undefined;
			if(self && self.annexaFormly && self.annexaFormly.model && self.annexaFormly.model.modal_body  && self.annexaFormly.dataAux && self.annexaFormly.dataAux.module && self.annexaFormly.dataAux.table){
				model = self.annexaFormly.model.modal_body;
				var modModified = self.annexaFormly.dataAux.module;
				var tableModified = self.annexaFormly.dataAux.table;
				if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.filters_configuration && $rootScope.app.configuration.filters_configuration.filtersConfiguration && $rootScope.app.configuration.filters_configuration.filtersConfiguration.modules){
					_.forEach($rootScope.app.configuration.filters_configuration.filtersConfiguration.modules, function(mod){
						if(!module){
							if(modModified === mod.name){
								module = mod;
								if(!table && mod && mod.filters){
									_.forEach(mod.filters, function(tab){
										if(tableModified === tab.table){
											table = tab;
											calculateTable(tab, model);																						
										}
									});
								}
							}
						}
					});
				}
			}
			if(module && table){
				var prederFilters = JSON.stringify($rootScope.app.configuration.filters_configuration);
            	AdminFactory.updateConfiguration('filters_configuration', prederFilters, undefined)
                .then(function(data) {
                    reloadModules();
					self.close();
                    var indexConfig = $linq(GlobalDataFactory.configuration).indexOf("x => x.key === 'filters_configuration'");
                    if(indexConfig != -1) {
                    	GlobalDataFactory.configuration = [];
                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                        	CacheFactory.get('globalDataCache').remove('configuration')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }
                }).catch(function (error) {
                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
                });
			}else{
				self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
			}
        }

		var calculateTable = function(table, model){
			if(table && table.filters && model){
				_.forEach(table.filters, function(filter){
					if(filter.type === 'text' && filter.name){
						if(model[filter.name]){
							filter.value = model[filter.name];
						}else{
							filter.value = "";
						}
						filter.negated = ((model[filter.name+"_negated"])?"true":"false");																
					}else if(filter.type === 'checkbox' && filter.name){
						if(model[filter.name]){
							filter.value = model[filter.name]+"";
						}else{
							filter.value = "";
						}								
					}else if(filter.type === 'dateRange' && filter.name){
						if(model[filter.name+"From"] || model[filter.name+"To"]){
							var dateAux = "";
							var dateFromAux = "";
							var dateToAux = "";
							filter.value = "";
							if(model[filter.name+"From"]){
								dateFromAux =$filter('date')(model[filter.name+"From"], 'dd/MM/yyyy');
								filter.value += "dataFrom;"+dateFromAux;
							}
							if(model[filter.name+"To"]){
								dateToAux = $filter('date')(model[filter.name+"To"], 'dd/MM/yyyy');
								filter.value += ((model[filter.name+"From"])?";":"")+"dataTo;"+dateToAux;
							}	
						}else{
							filter.value = "";
						}	
						filter.negated = ((model[filter.name+"_negated"])?"true":"false");						
					}else if(filter.type === 'select' && filter.name){
						if(model[filter.name]){
							if(model[filter.name].id){
								filter.value = model[filter.name].id+"";
							}else{
								filter.value = model[filter.name]+"";
							}
						}else{
							filter.value = "";
						}											
						filter.negated = ((model[filter.name+"_negated"])?"true":"false");
					}else if(filter.type === 'select-multiple' && filter.name){
						if(model[filter.name] && model[filter.name].length > 0){
							var list = "";
							_.forEach(model[filter.name], function(val){
								if(val && val.id){
									list += ((list === "")?'':";")+val.id;
								}else{
									list += ((list === "")?"":";")+val;
								}
							});
							filter.value = list;
						}else{
							filter.value = "";
						}				
						filter.negated = ((model[filter.name+"_negated"])?"true":"false");
					}else if(filter.type === 'select-tree' && filter.name){
						if(model[filter.name] && model[filter.name].$selected){
							if(model[filter.name].$selected.id){
								filter.value = model[filter.name].$selected.id+"";
							}else{
								filter.value = model[filter.name].$selected+"";
							}
						
						}else{
							filter.value = "";
						}						
						filter.negated = ((model[filter.name+"_negated"])?"true":"false");
					}
				});
			}
		}
        
    }])