angular
    .module('annexaApp')
    .controller('AspectsController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'Language', 'AnnexaModalFactory', 'AdminFactory', 'CommonAdminService','HelperService', function ($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, Language, AnnexaModalFactory, AdminFactory, CommonAdminService, HelperService) {
        var clickNewCustomField = function (modalAspect, edit) {
            var createCustomFieldFunction = function() {
                var self = this;
                var data = this.annexaFormly.model.modal_body;

                if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3']) {
                    this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$validate();
                }

                if(this.annexaFormly.form.$valid) {
                    var customField =
                        {
                            language1: data.language1,
                            language2: data.language2,
                            language3: data.language3,
                            backendType: data.backendType,
                            frontendType: data.frontendType,
                            listValues: data.listValues,
                            templateTag: data.templateTag
                        }

                    CommonAdminService.insertCustomField(customField)
                        .then(function (data) {
                            var newCustomField = JSOG.decode(data);
                            AdminFactory.customFields.push(newCustomField);

                            if(!edit) {
                                modalAspect.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = $linq(AdminFactory.customFields).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();

                                if (!modalAspect.annexaFormly.model.modal_body.customFields) {
                                    modalAspect.annexaFormly.model.modal_body.customFields = [];
                                }

                                modalAspect.annexaFormly.model.modal_body.customFields.push(newCustomField.id);
                            } else {
                                newCustomField.checked = true;
                                modalAspect.customFields.push(newCustomField);
                                $scope.updateAspect(modalAspect.customFields, "customFields")
                            }

                            self.close();
                        });
                }
            }

            var modal = CommonAdminModals.customFields;

            modal.alerts = [];
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {language1:'', language2:'', language3:'', frontendType:'', backendType:'', listValues:[]};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            AnnexaFormlyFactory.showModal("modalCustomFields" + new Date().getTime(), modal, createCustomFieldFunction, false);
        }

        $scope.newElement = function() {
            var modal = CommonAdminModals.aspectNew;
            modal.additionalButton = {
                label: 'global.literals.newField',
                click: clickNewCustomField
            }
            modal.annexaFormly.model = { modal_body: {} };
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewAspect', modal, $scope.saveAspect, false);
        };

        $scope.saveAspect = function() {
            var self = this;

            if(this.annexaFormly.form.$valid) {
                var data = this.annexaFormly.model.modal_body;

                var model = {
                    language1: data.language1,
                    language2: data.language2 ? data.language2 : undefined,
                    language3: data.language3 ? data.language3 : undefined,
                    customFields: []
                }

                if(data.customFields) {
                    _.forEach(data.customFields, function(item) {
                        model.customFields.push({ customField: { id: item } });
                    })
                }

                RestService.insert('./api/aspect', model)
                    .then(function(data) {
                        $rootScope.$broadcast('annexaAdminBaseReloadTable');
                        self.close();
                    }).catch(function(error) {
                        console.error(error);
                });
            }
        }

        $scope.viewElement = function(id) {
            RestService.findOne('Aspect', id)
                .then(function(data) {
                    var modal = CommonAdminModals.aspectEdit;

                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    AdminFactory.aspectEdit = angular.copy(modal.data);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updateAspect = $scope.updateAspect;
                    modal.printCustomFields = function(model) {
                        var customFields = '';

                        _.forEach(model, function(val, key) {
                            if(val.checked) {
                                if(customFields != '') {
                                    customFields += ', ';
                                }

                                customFields += val[Language.getActiveColumn()];
                            }
                        })

                        return customFields;
                    };
                    modal.customFields = [];
                    _.forEach(angular.copy($linq(AdminFactory.customFields).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray()), function(item) {
                        if($linq(modal.data.customFields).count("x => x.customField.id == " + item.id) == 0) {
                            item.checked = false;
                        } else {
                            item.checked = true;
                        }
                        modal.customFields.push(item);
                    });
                    modal.submitLabel = 'global.literals.newField';
                    modal.submitFunction = function() {
                        clickNewCustomField(modal, true);
                    }
                    AnnexaModalFactory.showModal('modalAspectEdit', modal);
                }).catch(function(error) {
                    console.error(error);
            });
        }

        $scope.updateAspect = function (val, prop) {
            switch (prop) {
                case 'language1':
                case 'language2':
                case 'language3':
                    AdminFactory.aspectEdit[prop] = val[prop];
                    break;
                case 'customFields':
                    _.forEach(val, function(item) {
                        var index = $linq(AdminFactory.aspectEdit.customFields).indexOf("x => x.customField.id == " + item.id);

                        if(index != -1) {
                            if(item.checked) {
                                AdminFactory.aspectEdit.customFields[index].deleted = false;
                                AdminFactory.aspectEdit.customFields[index].removedDate = null;
                            } else {
                                AdminFactory.aspectEdit.customFields[index].deleted = true;
                                AdminFactory.aspectEdit.customFields[index].removedDate = new Date();
                                AdminFactory.aspectEdit.customFields[index].customField = item;
                            }
                        } else {
                            if(item.checked) {
                                delete item.checked;
                                AdminFactory.aspectEdit.customFields.push({ customField: item, aspect: { id: -1 } });
                            }
                        }
                    })
                    break;
            }

            var self = this;

            RestService.update('./api/aspect/' + AdminFactory.aspectEdit.id, AdminFactory.aspectEdit)
                .then(function(data) {
                    AdminFactory.aspectEdit = data;
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    self.customFields = [];
                    _.forEach(angular.copy($linq(AdminFactory.customFields).orderBy("x => x." + Language.getActiveColumn()).toArray()), function(item) {
                        if($linq(data.customFields).count("x => x.customField.id == " + item.id) == 0) {
                            item.checked = false;
                        } else {
                            item.checked = true;
                        }
                        self.customFields.push(item);
                    });
                }).catch(function(error) {
                    console.error(error);
            });
        }
    }]);