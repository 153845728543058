/**
 * Created by dpardell on 23/11/2015.
 */

angular
.module('annexaApp')
    .controller('TemplateTypesController', ['$scope', '$http', '$state', '$compile', '$filter', 'apiAdmin', 'CommonAdminService','$nestable', 'HeaderService','$rootScope', 'Language', 'AdminFactory', 'CommonService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'dialogs', 'AnnexaModalFactory', 'ErrorFactory', 'HelperService', 'DialogsFactory', '$q',
            function ($scope, $http, $state, $compile, $filter, apiAdmin, CommonAdminService, $nestable, HeaderService, $rootScope, Language, AdminFactory, CommonService, CommonAdminModals, AnnexaFormlyFactory, dialogs, AnnexaModalFactory, ErrorFactory, HelperService, DialogsFactory, $q) {
        $rootScope.loading(false);
        $scope.templateTypes = [];
        $scope.langColumn = Language.getActiveColumn();

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.template_types') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#admin-template-types','fa fa-plus','global.commonAdmin.literals.newTemplateType',undefined,'newTemplateType'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#admin-template-types','fa fa-download','global.literals.export',undefined,'exportTemplateTypes')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.templateTypes = CommonService.getNestableData(AdminFactory.templateTypes, 'templateType','orderView');

        $scope.toggle = function (scope) {
            scope.toggle();
        };

        $scope.newTemplateType = function (node) {
            var modal = angular.copy(CommonAdminModals.templateTypeNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.options = {};
            if(node && !node.pageX) {
                modal.annexaFormly.fields[0].fieldGroup[1].defaultValue = node;
            }
            AnnexaFormlyFactory.showModal("modalTemplateType", modal, $scope.saveTemplateType, false);
        }

        $scope.viewTemplateType = function (template) {
            var modal = angular.copy(CommonAdminModals.templateTypeEdit);
            modal.data = angular.copy(template);
            modal.languageColumn = Language.getActiveColumn();
            modal.updateTemplateType = $scope.updateTemplateType;

            AnnexaModalFactory.showModal('modaTemplateTypeEdit', modal);
        }

        $scope.deleteTemplateType = function(template) {
            var dlg = dialogs.confirm(
                $filter('translate')('global.commonAdmin.literals.deleteTemplateType'),
                $filter('translate')('global.commonAdmin.literals.confirmDeleteTemplateTypeMessage'),
                {
                    animation: true,
                    backdrop: 'static',
                    keyboard: true,
                    size: ''
                }
            );

            dlg.result.then(function (btn) {
                AdminFactory.deleteTemplateType(template)
                    .then(function (data) {
                        $scope.templateTypes = CommonService.getNestableData(AdminFactory.templateTypes, 'templateType','orderView');
                    }).catch(function (error) {
                    var errorTranslate = { msg: ErrorFactory.getErrorMessage('templateType', 'delete', error.data) };
                    var err = dialogs.error(
                        $filter('translate')('DIALOGS_ERROR_MSG'),
                        $filter('translate')(errorTranslate.msg),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: ''
                        }
                    );
                 });
            }, function (btn) {
            });
        }

        $scope.updateTemplateType = function(val, prop) {
            $scope.saveTemplateType(val);
        }

        $scope.saveTemplateType = function (template, deferred) {
            var templateModel = template;
            var isNew = false;
            var modal = undefined;

            if(template.hasOwnProperty('annexaFormly')) {
                modal = this;
                templateModel = template.annexaFormly.model.row1;
                isNew = true;
            }

            AdminFactory.newTemplateType(templateModel)
                .then(function(data) {
                    $scope.templateTypes = CommonService.getNestableData(AdminFactory.templateTypes, 'templateType','orderView');
                    if(isNew && modal) {
                        modal.close();
                    }
                    
                    if (deferred) {
                    	deferred.resolve(true);
                    }
                }).catch(function (error) {
                	if (deferred) {
                		deferred.resolve(false);
                	}
            });
        }

        $scope.treeOptions = {
            beforeDrop : function (e) {
            	var deferred = $q.defer();
            	DialogsFactory.confirm('global.commonAdmin.literals.moveTemplateType', 'global.commonAdmin.literals.confirmMoveTemplateType').then(function (dataAux) {
	                var sourceValue = e.source.nodeScope.$modelValue.templateType;
	                var sourceParentValue = null;
	                if( e.source.nodeScope.$modelValue.templateType.parent != null &&  e.source.nodeScope.$modelValue.templateType.parent != undefined) {
	                    sourceParentValue = e.source.nodeScope.$modelValue.templateType.parent.id;
	                }
	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.templateType: null;
	                if(destValue != null && destValue != undefined) {
                    	sourceValue.parent = destValue;
	                }else {
	                    sourceValue.parent = null;
	                }
	                if(e.dest != null){
	                	sourceValue.orderView = e.dest.index+1;
	                }
                    $scope.saveTemplateType(sourceValue, deferred);
            	}).catch(function (data) {
                	deferred.reject(false);
	            });  
            	
            	return deferred.promise;            	
            }
        };

        $scope.exportTemplateTypes = function(){
            $rootScope.showLoadingdivSignin = true;
            try {
                var cells = [$scope.langColumn];
                var fileName = "templateTypes.xls";
                var xlsFile = HelperService.exportToExcel($scope.templateTypes, 'nodes', cells, 'templateType', fileName, window, 'global.commonAdmin.tabs.templateTypes');
                $rootScope.showLoadingdivSignin = false;
            } catch(e) {
                $rootScope.showLoadingdivSignin = false;
                console.log(e.message);
            }
        }
    }]);
