angular
    .module('annexaApp')
        .controller('OperationTypeAdminController',['$scope', '$rootScope', 'CommonAdminModals', 'Language', '$filter', 'RestService', 'AnnexaModalFactory', 'RegFactory', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 
        	function ($scope, $rootScope, CommonAdminModals, Language, $filter, RestService, AnnexaModalFactory, RegFactory, GlobalDataFactory, CacheFactory, apiAdmin) {
        	 	$scope.operationTypeNew = CommonAdminModals.operationTypeNew;
        	
        	 	$scope.saveNewOperationType = function(){
                    if (this.annexaFormly.form.$valid) {
                    	var self = this;
                    	var data = this.annexaFormly.model.modal_body;
                    	var sendData = {
                    		code:((data.code)?data.code:undefined),
                    		type:((data.type)?data.type:undefined),
                    		language1:((data.language1)?data.language1:undefined),
                    		language2:((data.language2)?data.language2:undefined),
                    		language3:((data.language3)?data.language3:undefined),
                    		relatedThirds:((data.relatedThirds_false != undefined)?data.relatedThirds_false:((data.relatedThirds_true != undefined)?data.relatedThirds_true:false)),
                    		parentOperation:((data.parentOperation != undefined)?data.parentOperation:'OPTIONAL'),
                    		previousOperationTypes: []
                    	}
                    	if(data.previousOperationTypes && data.previousOperationTypes.length > 0){
                    		_.forEach(data.previousOperationTypes, function(pot){
                    			sendData.previousOperationTypes.push({previousOperationType:{id:pot}});
                    		});
                    	}
                        RestService.insert('./api/operation_type', sendData).then(function (dataGet) {
                        	$rootScope.$broadcast('annexaAdminBaseReloadTable');
                        	self.close();
                        	if(GlobalDataFactory.operationTypes){
                            	GlobalDataFactory.operationTypes.length = 0;
                            }else{
                            	GlobalDataFactory.operationTypes = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('OperationType')){
                            	CacheFactory.get('globalDataCache').remove('OperationType')
                            }
                            GlobalDataFactory.loadGlobalData();
                        }).catch(function (error) {
                        	self.alerts.push({ msg: $filter('translate')('global.operationType.errorCreate') });
                        });
                    }
                };
                
                $scope.filter = [
                    { id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
                    { id: 'code', type: 'text', order: 2, label: 'global.literals.codeType' }
                ]

                $scope.additionalColumns = [
                    { id: 'code', title: $filter('translate')('global.literals.codeType')},
                    { id: 'type', title: $filter('translate')('global.literals.type')},
                    { id: 'relatedThirds', width: '10%', column: new BooleanColumn($filter, 'global.operationType.relatedThirds')},
                    { id: 'parentOperation', width: '10%', column: new AngularTranslateColumn($filter, 'global.operationType.parentOperation', 'global.literals.', true)}
                ]

                $scope.viewElement = function (id) {
                    if(id) {
                    	RestService.findOne('OperationType', id).then(function(data) {
                            var modal = angular.copy(CommonAdminModals.operationTypeEdit);
                            modal.data = JSOG.decode(data.data);
                            modal.alerts = [];
                            modal.languageColumn = Language.getActiveColumn();
                            modal.otherLangColumn = 'name';
                            modal.otherLangColumn2 = 'description';
                            modal.yesno = [
                                { id: true, name: $filter('translate')('global.literals.yes') },
                                { id: false, name: $filter('translate')('global.literals.no') }
                            ];
                            modal.operationTypeList = apiAdmin.operationTypeList;
                            modal.printYesNo = function(modelValue) {
                                if(modelValue) {
                                	if(modelValue.id != undefined){
                                		if(modelValue.id){
                                			return $filter('translate')('global.literals.yes');
                                		}else{
                                			return $filter('translate')('global.literals.no');
                                		}
                                	}else{
                                		return $filter('translate')('global.literals.yes');
                                	}
                                } else {
                                    return $filter('translate')('global.literals.no');
                                }
                            };
                            modal.printOperationTypeList = function(modelValue) {
                                if(modelValue) {
                                	if(modelValue.id){
                                		return $filter('translate')('global.literals.'+modelValue.id);
                                	}else{
                            			return $filter('translate')('global.literals.'+modelValue);
                                	}
                                } else {
                                    return '';
                                }
                            };
                            modal.previousOperationTypes = angular.copy(GlobalDataFactory.operationTypes);
                            modal.data.previousOperationTypesAux = $linq(modal.previousOperationTypes).where(function(x){
                            	var ok = false;
                            	if(modal.data.previousOperationTypes && modal.data.previousOperationTypes.length > 0){
                            		_.forEach(modal.data.previousOperationTypes, function(pot){
                            			if(pot.previousOperationTypeId && pot.previousOperationTypeId == x.id){
                            				 ok = true;
                            			}
                            		});
                            	}
                            	return ok;
                            }).toArray();
                            modal.printPreviousOperationTypes = function(modelValue){
                            	var profiles = '';
                            	if(modelValue && modelValue.length > 0){
                            		_.forEach(modelValue, function(value){
                            			if(profiles != '') {
                            				profiles += ', ';
                                        }
                            			profiles += value[modal.languageColumn];
                            		});
                            	}
                            	return profiles;
                            }
                            modal.updateOperationType = $scope.updateOperationType;
                            AnnexaModalFactory.showModal('modalOperationTypeEdit', modal);
                        });
                    }
                };
        	   
                $scope.updateOperationType = function(val, prop) {
                	var operationType = angular.copy(this.data);
                	var self = this;
                	self.alerts = [];
                	if(_.contains(['relatedThirds','parentOperation'], prop)) {
                		operationType[prop] = val.id;
                    } else if(_.contains(['code','type'], prop)) {
                    	operationType[prop] = val;
                    }else if(_.contains(['previousOperationTypes'], prop)){
                    	var exist = $linq(operationType[prop]).intersect(val, "(x, y) => x.id == y.previousOperationTypeId").toArray();
                    	var notExist = [];
                    	_.forEach(val, function(opType) {
                    		var existoperationType = false;
                    		_.forEach(operationType[prop], function(previousOperationType) {
                    			if(opType.id == previousOperationType.previousOperationTypeId){
                    				existoperationType = true;
                        		}	
                        	});
                    		if(!existoperationType){
                    			notExist.push(opType);
                    		}
                    	});
                    	operationType[prop].length = 0;
                    	if(exist && exist.length > 0){
        	            	_.forEach(exist, function(opType){
        	            		if(opType.operationTypeId){
        	            			opType.operationType = {id:opType.operationTypeId}
        	            		}
        	            		if(opType.previousOperationTypeId){
        	            			opType.previousOperationType = {id:opType.previousOperationTypeId}
        	            		}
        	            		operationType[prop].push(opType);
        	            	});
                    	}
                    	if(notExist && notExist.length > 0){
                    		_.forEach(notExist, function(opType){
                    			operationType[prop].push({previousOperationType:opType});
        	            	});
                    	}
                    }
                	if(!_.contains(['previousOperationTypes'], prop) && operationType.previousOperationTypes && operationType.previousOperationTypes.length > 0){
                		_.forEach(operationType.previousOperationTypes, function(opType){
    	            		if(opType.operationTypeId){
    	            			opType.operationType = {id:opType.operationTypeId}
    	            		}
    	            		if(opType.previousOperationTypeId){
    	            			opType.previousOperationType = {id:opType.previousOperationTypeId}
    	            		}
    	            	});
                	}
                	RestService.update('./api/operation_type/'+operationType.id, operationType).then(function (dataGetDocument) {
                		if(prop == 'previousOperationTypes'){
                    		self.data[prop] = dataGetDocument[prop];
                    	}
                		if(prop == 'relatedThirds'  || prop == 'parentOperation'){
                			self.data[prop] = dataGetDocument[prop];
                		}
                		$rootScope.$broadcast('annexaAdminBaseReloadTable');
                    	if(GlobalDataFactory.operationTypes){
                        	GlobalDataFactory.operationTypes.length = 0;
                        }else{
                        	GlobalDataFactory.operationTypes = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('OperationType')){
                        	CacheFactory.get('globalDataCache').remove('OperationType')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                    	self.alerts.push({ msg: $filter('translate')('global.operationType.errorCreate') });
                    });
                };
                
            }]);