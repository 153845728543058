/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('ProfileAdminController', ['$scope', '$http', '$state', '$compile', '$filter', 'DTColumnBuilder','CommonAdminService', 'apiAdmin', 'AnnexaFormlyFactory', 'CommonAdminModals', '$rootScope', 'HeaderService', 'AdminFactory', 'RestService', 'AnnexaModalFactory', 'TableFilter','Language', 'dialogs', 'GlobalDataFactory', 'CacheFactory','CommonService','DialogsFactory',
        function ($scope, $http, $state, $compile, $filter, DTColumnBuilder, CommonAdminService, apiAdmin, AnnexaFormlyFactory, CommonAdminModals, $rootScope, HeaderService, AdminFactory, RestService, AnnexaModalFactory, TableFilter, Language, dialogs, GlobalDataFactory, CacheFactory, CommonService, DialogsFactory) {

    		$scope.userProfiles = [];
    	
            // Funcions control mostrar botó Activar/Desactivar
            // -------------------------------------------------
            var showEnableAction = function (data, type, full, meta) {
                if(full.expiryDate == null || new Date() < new Date(full.expiryDate)){
                    return false;
                } else {
                    return true;
                }
            }

            var showDisableAction = function (data, type, full, meta) {
                if(full.expiryDate == null || new Date() < new Date(full.expiryDate)){
                    return true;
                } else {
                    return false;
                }
            }
            // -------------------------------------------------

            // TAULA PRINCIPAL
            // ----------------
            $scope.tfilter = new TableFilter();
            $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');
            
            $scope.dtDefProfiles = new TableData('common', 'Profile', './api/common/byfiler').setSort([[2, 'asc']]).setSortName('name');

            var ProfilesNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
            var ProfilesActionColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','viewProfile([data])','fa-eye'),[
                    new ActionButton('global.commonAdmin.datatable.options.disable','disableProfile([data])', undefined, undefined, showDisableAction),
                    new ActionButton('global.commonAdmin.datatable.options.enable','enableProfile([data])', undefined, undefined, showEnableAction)
                ]);
            
            $scope.filterData = { profileStatus: ''};
            var profileActiveColumn = new ActiveByDateColumn($scope, 'filterData', 'profileStatus',
                    [
                        new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                        new IconSearchOption(1, 1, 'm-l-xs fa fa-circle text-success', 'm-l-xs fa fa-circle text-success', 'global.commonAdmin.filter.statuses.active'),
                        new IconSearchOption(2, 2, 'm-l-xs fa fa-circle text-danger', 'm-l-xs fa fa-circle text-danger', 'global.commonAdmin.filter.statuses.inactive'),
                    ], 'tableProfiles');

            $scope.createDateColumn = new DateColumn($filter,'global.commonAdmin.datatable.createDate');
            $scope.expiryDateColumn = new DateColumn($filter,'global.commonAdmin.datatable.expiryDate');

            $scope.getFilterCallAux = function(){
                var filtercallaux = { langColumn: Language.getActiveColumn() };
                if($scope.filterData.profileStatus){
                    filtercallaux.status = {id:$scope.filterData.profileStatus};
                }
                return filtercallaux;
            }
            $scope.tableProfiles = {
                dtColumns:[
                    DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                    DTColumnBuilder.newColumn('expiryDate').withOption('width', '5%').withTitle(profileActiveColumn.getTitle()).renderWith(profileActiveColumn.getRender).notSortable(),
                    DTColumnBuilder.newColumn('acronym').withOption('width', '120px').withTitle($filter('translate')('global.literals.acronym')),
                    DTColumnBuilder.newColumn(ProfilesNameColumn.getColumn()).withTitle(ProfilesNameColumn.getTitle()),
                    DTColumnBuilder.newColumn('profileCode').withOption('width', '120px').withTitle($filter('translate')('global.literals.code')),
                    DTColumnBuilder.newColumn('id').withOption('width', '120px').withOption('className', 'text-center').withTitle(ProfilesActionColumn.getTitle()).notSortable().renderWith(ProfilesActionColumn.getRender)
                ],
                filterCall: $scope.tfilter.getFilterCall(),
                filterCallAux : $scope.getFilterCallAux(),
                reloadInternalData: function (resetPaging) {
                    this.filterCall = $scope.tfilter.getFilterCall();
                    this.filterCallAux = $scope.getFilterCallAux();
                    this.table.reloadData(function callbackInstance(json){}, resetPaging);
                }
            };
            // ----------------

            // Modal de nou Perfil
            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.admin.profiles') {
                    $rootScope.subHeadButtons = [
                        new HeadButtonSearch("#tableFilter"),
                        new HeadButton('btn primary breadDivButton mr-sm-pt mb-sm-pt mb-md-0',undefined,'#admin-profiles','fa fa-plus','global.commonAdmin.modal.profile.titleNew',undefined,'openNewProfile'),
                        new HeadButton('btn primary breadDivButton mr-sm-pt mb-sm-pt mb-md-0',undefined,'#admin-profiles','fa fa-download','global.literals.export',undefined,'exportProfiles'),
                        new HeadButton('btn primary breadDivButton mr-sm-pt mb-sm-pt mb-md-0',undefined,'#admin-profiles','fa fa-upload','global.literals.import',undefined,'importProfiles')
                    ];
                }
            });
            HeaderService.changeState($state.current);

        $scope.doSearchFilter=function(queued) {
            $scope.tableProfiles.reloadInternalData(true);
        }
        
        RestService.findAll('Role').then(function (data) {
        	$scope.roles = JSOG.decode(data.data);
        });
        
        var transformProfilesToServerProfile = function (userProfiles, property) {
            var profiles = [];
            angular.forEach(userProfiles, function (val) {
                if(val[property] && val[property].id){
                    val[property] = val[property].id;
                }
                profiles.push(val);
            });

            return profiles;
        };

        // Edició Perfils
        // ---------------
        $scope.viewProfile = function (id) {
            RestService.findOne('Profile', id)
                .then(function (data) {
                	$scope.userProfiles = [];
                    var modal = CommonAdminModals.profileEdit;
                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    AdminFactory.profileEdit = angular.copy(modal.data);
                    delete AdminFactory.profileEdit.content;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updateProfile = $scope.updateProfile;
                    
                    angular.forEach(modal.data.userProfiles,function(value, key){
                        if(value.user){
                        	var name = value.user.name;
                            if(value.user.surename1){ name = name + " "+value.user.surename1};
                            if(value.user.surename2){name = name + " "+value.user.surename2};
                            value.userSelect = { id: value.user.id, object: {id:value.user.id, name: value.user.name, surename1:value.user.surename1, surename2:value.user.surename2}, value: name };
                        }
                    });
                    
                    modal.definitionUsers = new editableTable(
                            "users",
                            "global.literals.users",
                            "global.commonAdmin.modal.profile.addUser",
                            function () {
                                this.inserted = {
                                    user: { id: -1, identifier: '', name: '', surename1: '', completeName: '' },
                                    roles: []
                                };

                                this.items.push(this.inserted);
                            },

                            function (rowform, index, cancel) {
                                var self = this;
                                modal.definitionUsers.showTableError = false;
                                modal.definitionUsers.tableErrorText = "";
                                if(cancel) {
                                    if(self.items[index] && !self.items[index].id){
                                        self.items.splice(index, 1);
                                    }
                                    rowform.$cancel();
                                } else {
                                    var exist = false;
                                    if(index >= 0 && self.items[index]) {
                                        angular.forEach(self.items, function (value, key) {
                                            if (value.userSelect && value.userSelect.id == self.items[index].user.id && key != index) {
                                                exist = true;
                                            } else if (value.user.id == self.items[index].user.id && key != index) {
                                            	exist = true;
                                            }
                                        });
                                    }
                                    if(exist) {
                                        modal.definitionUsers.showTableError = true;
                                        modal.definitionUsers.tableErrorText = $filter("translate")("global.literals.duplicateProfileUser");
                                        return "Error";
                                    }else{
                                        var model = this.containerObject;
                                        $scope.updateProfile(model.userProfiles[index], "insertUpdateUsers")
                                    }
                                }
                            },

                            function ($index) {
                            	var self = this;
                            	AdminFactory.profileEdit.userProfiles[$index].deleted  = true;
                            	AdminFactory.profileEdit.userProfiles[$index].removedDate = new Date();
                            	self.items.splice($index, 1);
                                $scope.updateProfile($index, "deleteUsers");
                            },modal.data)
                            .setItems(modal.data.userProfiles)
                            .addColumns([
                                new editableTableColumnDefaultProfile(
                                    'user',
                                    'global.literals.user',
                                    '30%',
                                    function (user) {
                                        return user.user.completeName;
                                    })
                                    .setLoadUserColumn(undefined,
                                    	function (user) {
	                                    	if(user) {
	                                    		return user.value;
	                                    	} else {
	                                    		return "";
	                                    	}
                                    	},
                                    	CommonService.loadUsers,
                                    	function ($data, column, item) {
                                            if(column.required && !$data) {
                                            	return $filter('translate')('global.validation.required');
                                            } else {
                                            	if($data.object) {
                                            		item.user = $data.object;
                                            	} else {
                                            		item.user = $data.user;
                                            	}
                                            }
                                    	},
                                    	true
                                    ),
                                new editableTableColumnDefaultProfile(
                                    'roles',
                                    'global.literals.rols',
                                    '62%',
                                    function (roles) {
                                        var result = '';

                                        angular.forEach(roles.roles, function (role) {
                                            if(result != '') {
                                                result += ', ';
                                            }
                                            result += role[Language.getActiveColumn()];
                                        });

                                        return result;
                                    })
                                    .setSelectColumn(
                                        Language.getActiveColumn(),
                                        new SortedArray($scope.roles, $scope.languageColumn).sort(),
                                        function($data, profile, object) {
                                            profile.roles = [];

                                            if($data) {
                                                profile.roles = $data;
                                            }
                                        },
                                        true,
                                        undefined,
                                        'falso'
                                    )
                            ]);

                    AnnexaModalFactory.showModal("modalProfileEdit", modal);
                 })
        };
        // ---------------

        // Actualització Perfils
        // ----------------------
        $scope.updateProfile = function (val, prop) {
            switch (prop) {
                case 'language1':
                case 'language2':
                case 'language3':
                    AdminFactory.profileEdit.language1 = val.language1;
                    AdminFactory.profileEdit.language2 = val.language2;
                    AdminFactory.profileEdit.language3 = val.language3;
                    break;
                case 'acronym':
                    AdminFactory.profileEdit.acronym = val;
                case 'profileCode':
                    AdminFactory.profileEdit.profileCode = val;
                    break;
                case 'insertUpdateUsers':
                	var exist = false;
                	angular.forEach(AdminFactory.profileEdit.userProfiles, function (userProfile) {
                        if(userProfile.id == val.id){
                        	var user = {id: val.userSelect.id};
                        	userProfile.user = user;
                        	userProfile.roles = [];
                        	angular.forEach(val.roles, function (role) {
                        		userProfile.roles.push({id: role.id});
                            });
                            exist = true;
                        }
                    });
                	if(!exist) {
                		AdminFactory.profileEdit.userProfiles.push(val);
                	}
                	break;
            }

            var self = this;

            CommonAdminService.insertOrUpdateProfile(AdminFactory.profileEdit)
                .then(function (data) {
                    var decodedData = JSOG.decode(data);
                    AdminFactory.profileEdit = decodedData;
                    $scope.tableProfiles.reloadInternalData(false);
                    if(GlobalDataFactory.allProfiles){
                    	GlobalDataFactory.allProfiles.length = 0;
                    }else{
                    	GlobalDataFactory.allProfiles = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Profile&origin=allProfiles')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Profile&origin=allProfiles')
                    }
                    GlobalDataFactory.loadGlobalData();
                    self.alerts = [];
                    return data;
                }).catch(function (error) {
                	self.alerts = [];
                	if (error.message == 'DataIntegrityViolationException.acronym') {
                		self.alerts.push({msg: $filter('translate')('global.errors.existAcronym')});
                	} else if(error.message == 'DataIntegrityViolationException.profile_code') {                		
                		self.alerts.push({msg: $filter('translate')('global.errors.existCode')});
                	} else {                		
                		self.alerts.push({msg: $filter('translate')('global.errors.unknownUpdate')});
                	}
                });
        };
        // ----------------------

        // Desat Perfils
        // --------------
            $scope.saveProfile = function($modal) {
                if($modal.annexaFormly.form.$valid) {
                    var data = $modal.annexaFormly.model.modal_body;

                    var model = {
                        language1: data.language1,
                        language2: 'NotTranslated',
                        language3: 'NotTranslated',
                    };

                    if(data.language2) {
                        model.language2 = data.language2;
                    }

                    if(data.language3) {
                        model.language3 = data.language3;
                    }

                    if(data.acronym) {
                        model.acronym = data.acronym;
                    }

                    if(data.profileCode) {
                        model.profileCode = data.profileCode;
                    }
                    
                    if($scope.userProfiles && $scope.userProfiles.length != 0) {
                    	model.userProfiles = $scope.userProfiles;
                    	angular.forEach($scope.userProfiles, function (userProfile) {
                    		if(userProfile.userSelect) {
                    			userProfile.user = userProfile.userSelect.user;
                    		}
                        });
                    }

                    CommonAdminService.insertOrUpdateProfile(model)
                        .then(function (data) {
                            $scope.tableProfiles.reloadInternalData(false);
                            if(GlobalDataFactory.allProfiles){
                            	GlobalDataFactory.allProfiles.length = 0;
                            }else{
                            	GlobalDataFactory.allProfiles = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Profile&origin=allProfiles')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Profile&origin=allProfiles')
                            }
                            GlobalDataFactory.loadGlobalData();
                            $modal.close();
                        }).catch(function (error) {
                        	$modal.alerts = [];
                        	if (error.message == 'DataIntegrityViolationException.acronym') {
                        		$modal.alerts.push({msg: $filter('translate')('global.errors.existAcronym')});
                        	} else if(error.message == 'DataIntegrityViolationException.profile_code') {
                        		$modal.alerts.push({msg: $filter('translate')('global.errors.existCode')});
                        	} else {
                        		$modal.alerts.push({msg: $filter('translate')('global.errors.unknownNew')});
                        	}
                        }); 
                }
            };
        // --------------

        // MODAL Nou Perfil
        // -----------------
        $scope.openNewProfile = function (id, readonly) {
        	$scope.userProfiles = [];
            var modal = CommonAdminModals.profileNew;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: readonly};
            modal.annexaFormly.fields[0].fieldGroup[3].data.roles = $scope.roles;
            modal.annexaFormly.fields[0].fieldGroup[3].data.definition = new editableTable(
                    "users",
                    "global.literals.users",
                    "global.commonAdmin.modal.profile.addUser",
                    function () {
                        this.inserted = {
                            user: { id: -1, identifier: '', name: '', surename1: '', completeName: '' },
                            roles: []
                        };

                        this.items.push(this.inserted);
                    },

                    function (rowform, index, cancel) {
                        var self = this;
                        self.showTableError = false;
                        self.tableErrorText = "";
                        if(cancel) {
                            if(self.items[index] && self.items[index].user && self.items[index].user.id == -1){
                                self.items.splice(index, 1);
                            }
                            rowform.$cancel();
                        } else {
                            var exist = false;
                            if(index >= 0 && self.items[index]) {
                                angular.forEach(self.items, function (value, key) {
                                    if (value.user.id == self.items[index].user.id && key != index) {
                                        exist = true;
                                    }
                                });
                            }
                            if(exist) {
                            	self.showTableError = true;
                            	self.tableErrorText = $filter("translate")("global.literals.duplicateProfileUser");
                            	self.items[index].user = { id: -1, identifier: '', name: '', surename1: '', completeName: '' };
                                return "Error";
                            } else {
                            	$scope.userProfiles.push(self.items[index]);
                            }
                        }
                    },

                    function ($index) {
                    	var self = this;
                    	self.items.splice($index, 1);
                    	$scope.userProfiles.splice($index, 1);
                    },{})
                    .setItems([]);
            AnnexaFormlyFactory.showModal("modalProfiles", modal, $scope.saveProfile, false);
        }
        
        // ------------------
            
        // Activació / desactivació perfils
        // -----------------------------------
        $scope.disableProfile = function (id){
            var content = {
                id:id,
                expiryDate:new Date()
            };
            CommonAdminService.updateObject(apiAdmin.getProfiles,id,content).then(function (dataUpdate) {
                $scope.tableProfiles.reloadInternalData(true);
                if(GlobalDataFactory.allProfiles){
                	GlobalDataFactory.allProfiles.length = 0;
                }else{
                	GlobalDataFactory.allProfiles = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Profile&origin=allProfiles')){
                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Profile&origin=allProfiles')
                }
                GlobalDataFactory.loadGlobalData();
            }).catch(function () {
            });
        }

        $scope.enableProfile = function (id){
            var content = {
                id:id,
                expiryDate:null
            };
            CommonAdminService.updateObject(apiAdmin.getProfiles,id,content).then(function (dataUpdate) {
                $scope.tableProfiles.reloadInternalData(true);
                if(GlobalDataFactory.allProfiles){
                	GlobalDataFactory.allProfiles.length = 0;
                }else{
                	GlobalDataFactory.allProfiles = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Profile&origin=allProfiles')){
                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Profile&origin=allProfiles')
                }
                GlobalDataFactory.loadGlobalData();
            }).catch(function () {
            });
        }
        // -----------------------------------

        $scope.deleteProfile = function (id){
            CommonAdminService.deleteProfile(id)
                .then(function (data) {
                    var decodedData = JSOG.decode(data.data);
                    $scope.tableProfiles.reloadInternalData(false);
                    if(GlobalDataFactory.allProfiles){
                    	GlobalDataFactory.allProfiles.length = 0;
                    }else{
                    	GlobalDataFactory.allProfiles = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Profile&origin=allProfiles')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Profile&origin=allProfiles')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function (error) {
            });
        }

        $scope.exportProfiles = function(){
            $rootScope.showLoadingdivSignin = true;
            RestService.findAll('Profile', 'exportProfile').then(function (data) {
                var listProfiles = JSOG.decode(data.data);
                var contentType = 'application/octet-stream';
                var fileName = "profileUsersExport.xls";
                var xlsFile = '<?xml version="1.0"?>\n';
                xlsFile += '<ss:Workbook xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n';
                xlsFile += '<ss:Worksheet ss:Name="'+$filter('translate')('global.literals.profiles')+'">\n';
                xlsFile += '<ss:Table>\n\n';
                if(listProfiles){
                    angular.forEach(listProfiles, function(value, key){
                        xlsFile += '<ss:Row>\n';
                        xlsFile += '  <ss:Cell>\n';
                        xlsFile += '    <ss:Data ss:Type="String">' + value.id + '</ss:Data>\n';
                        xlsFile += '  </ss:Cell>\n';
                        xlsFile += '  <ss:Cell>\n';
                        xlsFile += '    <ss:Data ss:Type="String">' + value[Language.getActiveColumn()] + '</ss:Data>\n';
                        xlsFile += '  </ss:Cell>\n';
                        xlsFile += '</ss:Row>\n';
                        angular.forEach(value.userProfiles,function(value2, key2){
                            if(value2.user && !value2.user.deleted) {
                                xlsFile += '<ss:Row>\n';
                                xlsFile += '  <ss:Cell>\n';
                                xlsFile += '    <ss:Data ss:Type="String"></ss:Data>\n';
                                xlsFile += '  </ss:Cell>\n';
                                xlsFile += '  <ss:Cell>\n';
                                xlsFile += '    <ss:Data ss:Type="String"></ss:Data>\n';
                                xlsFile += '  </ss:Cell>\n';
                                xlsFile += '  <ss:Cell>\n';
                                xlsFile += '    <ss:Data ss:Type="String">';
                                xlsFile += value2.user.name;
                                xlsFile += " " + value2.user.surename1;
                                if (value2.user.surename2) {
                                    xlsFile += " " + value2.user.surename2;
                                }
                                xlsFile += '    </ss:Data>\n';
                                xlsFile += '  </ss:Cell>\n';
                                xlsFile += '</ss:Row>\n';
                            }
                        })
                    })
                }
                xlsFile += '\n</ss:Table>\n';
                xlsFile += '</ss:Worksheet>\n';
                xlsFile += '</ss:Workbook>\n';
                var file = new Blob([xlsFile], {type: contentType});
                if(window.navigator.msSaveOrOpenBlob) {
                    try {
                        $rootScope.showLoadingdivSignin = false;
                        window.navigator.msSaveOrOpenBlob(file, fileName);
                    } catch(e) {
                        console.log(e.message);
                    }
                } else {
                    var linkElement = document.createElement('a');
                    try {
                        var url = window.URL.createObjectURL(file);
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute("download", fileName);
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        $rootScope.showLoadingdivSignin = false;
                        linkElement.dispatchEvent(clickEvent);
                    } catch (ex) {
                        console.log(ex);
                        $rootScope.showLoadingdivSignin = false;
                    }
                }
            }).catch(function(error){
                var err = dialogs.error(
                    $filter('translate')('DIALOGS_ERROR_MSG'),
                    $filter('translate')('global.errors.exportError'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );
            });
        }
        
        $scope.importProfiles = function () {  
        	AdminFactory.importDataAdmin($scope, "PROFILES", $scope.tableProfiles);
        }        


    }]);
