/**
 * Created by dpardell on 01/12/2015.
 */
angular
    .module('annexaApp')
    .service('CommonAdminService',['$q', '$http','$filter', 'apiAdmin', '$rootScope', 'RestService', function ($q, $http, $filter, apiAdmin, $rootScope, RestService) {
        return {
            insertOrUpdatePresetSignCircuit:function(presetSignCircuit){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/presetsigncircuits/insertOrUpdatePresetSignCircuit',
                    data: JSOG.encode(presetSignCircuit)
                }).success(function(data, status) {
                        deferrend.resolve(data);
                }).error(function(msg,code) {
                        deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            deletePresetSignCircuit:function(id){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/presetsigncircuits/deletePresetSignCircuit',
                    params: {
                        id: id
                    }
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            insertOrUpdatePresetSignAction:function(presetSignAction){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/presetsigncircuits/insertOrUpdatePresetSignAction',
                    data: JSOG.encode(presetSignAction)
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            insertOrUpdateFamilyProcedure: function(family){
                var deferred = $q.defer();
                var send = { };
                send = JSOG.encode(family);
                $http({
                    url: './api/familyprocedures/insertOrUpdateFamily',
                    method: 'POST',
                    data: send
                }).then(function(data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            },
            deleteFamilyProcedure: function(id){
                var deferred = $q.defer();
                $http({
                    url: './api/familyprocedures/deleteFamily',
                    method: 'POST',
                    params: {
                        idFamily:id
                    }
                }).then(function(data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            },
            getIdsFromArray:function(array){
                var ids = [];
                if(array) {
                    for (var i = 0; i < array.length; i++) {
                        ids.push(array[i].id);
                    }
                }
                return ids;
            },
            getPresetSignActions:function(actions){
                var actionsNew = []
                if(actions){
                    for(var i=0; i<actions.length; i++){
                        var action = {
                            signatoryType:{id:actions[i].signatoryType.id},
                            signActionType:{id:actions[i].signActionType.id},
                            actionOrder:actions[i].actionOrder,
                            maxDurationDays:actions[i].maxDurationDays
                        };
                        if(actions[i].id){
                            action.id = actions[i].id;
                        }
                        if(actions[i].createdDate){
                            action.createdDate = new Date(actions[i].createdDate.time);
                        }
                        if(actions[i].presetSignCircuit){
                            action.presetSignCircuit = {id:actions[i].presetSignCircuit.id};
                        }
                        if(actions[i].user && actions[i].user.id){
                            action.user = {id:actions[i].user.id};
                        }
                        if(actions[i].actionLevel){
                            action.actionLevel = actions[i].actionLevel;
                        }
                        actionsNew.push(angular.toJson(action));
                    }
                }
                return angular.toJson(actionsNew);
            },
            updateObject:function(url,id,content){
                var deferrend = $q.defer();
                $http({
                    method: 'PATCH',
                    url: url+"/"+id,
                    headers: {'Content-Type': 'application/json'},
                    data:content
                }).success(function(data, status) {
                    deferrend.resolve(data);
                })
                .error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            insertOrUpdateObject:function(url,data){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: url,
                    headers: {'Content-Type': 'application/json'},
                    data: data,
                    }).success(function(data, status) {
                        deferrend.resolve(data);
                    })
                    .error(function(msg,code) {
                        deferrend.reject(msg);
                    });
                return deferrend.promise;
            },
            addPhoto: function(file){
                var deferrend = $q.defer();
                var formData=new FormData();
                if(file){
                    formData.append("file",file);
                }
                $http({
                    method: 'POST',
                    url: apiAdmin.addUserPhoto,
                    headers: {'Content-Type': undefined},
                    data: formData,
                    transformRequest: function(data, headersGetterFunction) {
                        return data;
                    }
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            deleteObject:function(url, id){
                var deferrend = $q.defer();
                $http({
                    method: 'DELETE',
                    url: url+"/"+id,
                     headers: {'Content-Type': 'application/json'}
                }).success(function(data, status) {
                    deferrend.resolve(data);
                })
                .error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            formatDate: function(date, add, del) {
                if (add) {
                    date.setDate(date.getDate() + 1);
                }
                if (del) {
                    date.setDate(date.getDate() - 1);
                }
                var dd = date.getDate();
                var mm = date.getMonth() + 1;
                var yyyy = date.getFullYear();
                if (dd < 10) {
                    dd = '0' + dd
                }
                if (mm < 10) {
                    mm = '0' + mm
                }
                return mm + '/' + dd + '/' + yyyy;
            },
            insertOrUpdateProfile:function(profile){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: apiAdmin.getProfileAdd,
                    data: JSOG.encode(profile)
                }).success(function(data, status) {
                        deferrend.resolve(data);
                    })
                    .error(function(msg,code) {
                        deferrend.reject(msg);
                    });
                return deferrend.promise;
            },
            deleteProfile:function(profileId){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: apiAdmin.getProfileDelete,
                    params: {
                        idProfile: profileId
                    }
                }).success(function(data, status) {
                    deferrend.resolve(data);
                })
                    .error(function(msg,code) {
                        deferrend.reject(msg);
                    });
                return deferrend.promise;
            },
            deleteCouncillor: function(councillorId){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: apiAdmin.deleteCouncilorById,
                    headers: {'Content-Type': undefined},
                    params: {
                        idCouncillor: councillorId
                    }
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            getCouncillorById:function(id){
                var deferrend = $q.defer();
                $http({
                    method: 'GET',
                    url: apiAdmin.getCouncillorById,
                    params: {
                        id: id
                    }
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                })
                return deferrend.promise;
            },
            getProfileById:function(id){
                var deferrend = $q.defer();
                $http({
                    method: 'GET',
                    url: apiAdmin.getProfileById,
                    params: {
                        id: id
                    }
                }).success(function(data, status) {
                    deferrend.resolve(angular.fromJson(data));
                })
                return deferrend.promise;
            },
            getSectionsByFilter:function(){
                var deferrend = $q.defer();
                $http({
                    method: 'GET',
                    url: apiAdmin.getSectionsByFilter,
                    params: {}
                }).success(function(data, status) {
                    deferrend.resolve(data);
                })
                return deferrend.promise;
            },
            checkDefaultProfileByUserAndEntityType: function(id, type, isInsert){
                var deferrend = $q.defer();
                $http.get(apiAdmin.getDefaultEntityProfilesByUserIdAndEntityType+'?id='+id+'&type='+type+'&isInsert='+isInsert).success(function(data, status) {
                    if(data != null) {
                        deferrend.resolve(data);
                    }else{
                        deferrent.resolve(null);
                    }
                }).error(function(msg, status) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            inserOrUpdateDefaultEntityProfile: function(defaultEntityProfile){
                var deferrend = $q.defer();
                $http({
                    url: apiAdmin.insertOrUpdateDefaultEntityProfile,
                    method: 'POST',
                    params: {
                        id: defaultEntityProfile.id,
                        userId: defaultEntityProfile.user,
                        profileId: defaultEntityProfile.profile,
                        type: defaultEntityProfile.entityType
                    }
                }).then(function (response) {
                    deferrend.resolve(response);
                }).catch(function (response) {
                    deferrend.resolve(response);
                });
                return deferrend.promise;
            },
            removeDefaultEntityProfile: function(data){
                var deferrend = $q.defer();

                $http({
                    method: 'POST',
                    url: apiAdmin.removeDefaultEntityProfile,
                    params: data
                }).success(function(res){
                    deferrend.resolve(res);
                }).error(function(res){
                    deferrend.reject(res);
                });
                return deferrend.promise;
            },
            removeUserProfile: function(userProfileId){
                var deferrend = $q.defer();

                $http({
                    method: 'POST',
                    url: apiAdmin.removeUserProfile,
                    params: {
                        id: userProfileId
                    }
                }).success(function(res){
                    deferrend.resolve(res);
                }).error(function(res){
                    deferrend.reject(res);
                });
                return deferrend.promise;
            },
            insertUnguidedProcedure:function(procedure, customFieldsProcedure,translations){
                var deferrend = $q.defer();
                var send = {
                    procedure:JSOG.encode(procedure),
                    customFields: JSOG.encode(customFieldsProcedure),
                    translations:translations
                };
                $http({
                    method: 'POST',
                    url: apiAdmin.insertUnguidedProcedure,
                    data: send
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            updateDocumentType: function(documentType){
                var deferred = $q.defer();

                RestService.update('./api/doc/document_type/' + documentType.id, documentType)
                    .then(function(data) {
                        deferred.resolve(data.data);
                    }).catch(function (error) {
                        deferred.reject(error);
                })

                return deferred.promise;
            },
            insertCustomField:function(customField){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/procedures/insertCustomField',
                    data: JSOG.encode(customField)
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            updateCustomField:function(customField){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/procedures/updateCustomField',
                    data: JSOG.encode(customField)
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            insertOrUpdateProcedureCustomField:function(customField){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/procedures/insertOrUpdateProcedureCustomField',
                    data: JSOG.encode(customField)
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            insertOrUpdateTransactionTypeCustomField:function(customField){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/transactiontypes/insertOrUpdateTransactionTypeCustomField',
                    data: JSOG.encode(customField)
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            insertOrUpdateProcedureClassification:function(classification){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/procedures/insertOrUpdateProcedureClassification',
                    data: JSOG.encode(classification)
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            updateUnguidedProcedure:function(procedure){
                var deferrend = $q.defer();
                var send = { };
                send = JSOG.encode(procedure);
                $http({
                    method: 'POST',
                    url: apiAdmin.updateUnguidedProcedure,
                    data: send
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            deleteProcedure:function(procedureId){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: apiAdmin.deleteProcedure,
                    params: {
                        idProcedure: procedureId
                    }
                }).success(function(data, status) {
                        deferrend.resolve(data);
                    })
                    .error(function(msg,code) {
                        deferrend.reject(msg);
                    });
                return deferrend.promise;
            },
            deleteTramTemplate:function(id){
                var deferrend = $q.defer();
                var send = { };
                send = JSOG.encode(id);
                $http({
                    method: 'POST',
                    url: './api/tram/deleteTemplate',
                    params: {idTemplate:id}
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.resolve(data);
                });
                return deferrend.promise;
            },
            getArchiveClassifications: function(){
            var deferrend = $q.defer();
            $http({
                url: './api/archiveClassifications/archiveClassification',
                method: 'GET'
            }).then(function (data) {
                deferrend.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferrend.resolve(error);
            });
            return deferrend.promise;
        },
        haveProcedureTransactionType: function(transactionTypeId){
            var deferrend = $q.defer();
            $http({
                url: './api/transactiontypes/haveProcedureTransactionType/' + transactionTypeId,
                method: 'GET'
            }).then(function (data) {
                deferrend.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferrend.resolve(error);
            });
            return deferrend.promise;
        },
            insertOrUpdateTransactionType: function(transactionType){
                var deferred = $q.defer();
                var send = { };
                send = JSOG.encode(transactionType);
                $http({
                    url: './api/transactiontypes/insertOrUpdateTransactionType',
                    method: 'POST',
                    data: send
                }).then(function(data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            },
            deleteTransactionType: function(id){
                var deferred = $q.defer();
                $http({
                    url: './api/transactiontypes/deleteTransactionType',
                    method: 'POST',
                    params: {
                        idTransactionType:id
                    }
                }).then(function(data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            },
            updateUserAlertMail:function(userAlertTypeEnum,status,idUser){
                var deferrend = $q.defer();
                var send = {
                		userAlertTypeEnum:JSOG.encode(userAlertTypeEnum),
                		status: JSOG.encode(status),
                		idUser: JSOG.encode(idUser)
                    };
                $http({
                    method: 'PUT',
                    url: './api/mailAlert',
                    headers: {'Content-Type': 'application/json'},
                    data: send,
                    }).success(function(data, status) {
                        deferrend.resolve(data);
                    })
                    .error(function(msg,code) {
                        deferrend.reject(msg);
                    });
                return deferrend.promise;
            },
            
            getUserMailAlerts:function(idUser){
                var deferrend = $q.defer();
                
                $http({
                    method: 'GET',
                    url: './api/mailAlert',
                    params: {
                    	idUser:idUser
                    }
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            insertQueryParameter:function(queryParameter){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/query/insertQueryParameter',
                    data: JSOG.encode(queryParameter)
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            updateQueryParameter:function(queryParameter){
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: './api/query/updateQueryParameter',
                    data: JSOG.encode(queryParameter)
                }).success(function(data, status) {
                    deferrend.resolve(data);
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            retryJobUploadDocument: function(id){
            	var deferred = $q.defer();
                $http({
                    url: './api/jobUploadDocument/retry',
                    method: 'POST',
                    params: {
                        idJobUploadDoc:id
                    }
                }).then(function(data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            },
            retryAllJobUploadDocument: function(){
            	var deferred = $q.defer();
                $http({
                    url: './api/jobUploadDocument/retryAll',
                    method: 'POST'
                }).then(function(data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }
        }
    }]);