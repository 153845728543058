/**
 * Created by dpardell on 23/11/2015.
 */

angular
.module('annexaApp')
    .controller('ClassificationController', ['$scope', '$http', '$state', '$compile', '$filter', 'apiAdmin', 'CommonAdminService','$nestable', 'HeaderService','$rootScope', 'Language', 'AdminFactory', 'CommonService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'dialogs', 'AnnexaModalFactory' , 'HelperService', 'DialogsFactory', '$q', 'GlobalDataFactory', 'CacheFactory', function ($scope, $http, $state, $compile, $filter, apiAdmin, CommonAdminService, $nestable, HeaderService, $rootScope, Language, AdminFactory, CommonService, CommonAdminModals, AnnexaFormlyFactory, dialogs, AnnexaModalFactory, HelperService, DialogsFactory, $q, GlobalDataFactory, CacheFactory) {
        $rootScope.loading(false);
        $scope.archiveClassifications = [];
        $scope.langColumn = Language.getActiveColumn();

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.classification') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#admin-classifications','fa fa-plus','global.commonAdmin.newClassification',undefined,'newClassification'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#admin-classifications','fa fa-download','global.literals.export',undefined,'exportClassifications'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#admin-classifications','fa fa-upload','global.literals.import',undefined,'importClassification')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.archiveClassifications = CommonService.getNestableData(GlobalDataFactory.archiveClassifications, 'archiveClassification', 'orderView');

        $scope.toggle = function (scope) {
            scope.toggle();
        };

        $scope.newClassification = function (node) {
            var modal = angular.copy(CommonAdminModals.classificationNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.options = {};
            if ($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.internal_control) {
            	modal.annexaFormly.model.row1 = {"internalControl": true};
            } else {
            	modal.annexaFormly.model.row1 = {"internalControl": false};
            }
            if(node && !node.pageX) {
                modal.annexaFormly.fields[0].fieldGroup[4].defaultValue = node == undefined ? null : node;
            }
            AnnexaFormlyFactory.showModal("modalClassification", modal, $scope.saveClassification, false);
        }

        $scope.viewClassification = function (classification) {
            var modal = angular.copy(CommonAdminModals.classificationEdit);
            modal.alerts = [];
            modal.data = angular.copy(classification);
            modal.data.inspectionIntervention = ((modal.data.inspectionIntervention != undefined)?{id:modal.data.inspectionIntervention}:undefined);
            modal.data.inspectionInterventionType = ((modal.data.inspectionInterventionType != undefined)?{id:modal.data.inspectionInterventionType}:undefined); 
            modal.data.closeExpireType = ((modal.data.closeExpireType != undefined)?{id:modal.data.closeExpireType}:undefined);
            modal.data.transferExpireType = ((modal.data.transferExpireType != undefined)?{id:modal.data.transferExpireType}:undefined);
            modal.data.archiveExpireType = ((modal.data.archiveExpireType != undefined)?{id:modal.data.archiveExpireType}:undefined);
            modal.data.automaticClose = ((modal.data.automaticClose != undefined)?{id:modal.data.automaticClose}:undefined);
            modal.data.automaticTransfer  = ((modal.data.automaticTransfer != undefined)?{id:modal.data.automaticTransfer}:undefined);
            modal.data.automaticArchive = ((modal.data.automaticArchive != undefined)?{id:modal.data.automaticArchive}:undefined);
            modal.dataAux = angular.copy(classification);
            modal.languageColumn = Language.getActiveColumn();
            modal.inspectionInterventionTypeColumn = "name";
            modal.inspectionInterventionTypes = apiAdmin.inspectionInterventionTypes;
            if ($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.internal_control) {
            	modal.internalControl = true;
            } else {
            	modal.internalControl = false;
            }
            modal.inspectionInterventionColumn = "description";
            modal.inspectionInterventions = apiAdmin.yesNoBooleanNull;
            modal.expireTypes = apiAdmin.expirationTypeList;
            modal.updateClassification = $scope.updateClassification;
            modal.updateEMGDEClassification = $scope.updateEMGDEClassification;
            modal.archiveTool = (($rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL')?true:false);
            modal.archiveGee = (($rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE')?true:false);
            modal.printExpireType = function (model) {
            	if(model && (model == 'BUSINESS' || model.id == 'BUSINESS')) {
                    return $filter('translate')('global.literals.BUSINESS');
                } else if(model && (model == 'NATURAL' || model.id == 'NATURAL')){
                    return $filter('translate')('global.literals.NATURAL');
                } else if(model && (model == 'MONTHS' || model.id == 'MONTHS')){
                    return $filter('translate')('global.literals.MONTHS');
                } else if(model && (model == 'YEARS' || model.id == 'YEARS')){
                    return $filter('translate')('global.literals.YEARS');
                }else{
                	return "";
                }
            }
            modal.printInspectionIntervention = function (model) {
                if(model == true || (model && model.id == true)) {
                    return $filter('translate')('global.literals.yes');
                } else if(model == false || (model &&  model.id == false)){
                    return $filter('translate')('global.literals.no');
                }else{
                	return $filter('translate')('global.literals.empty');
                }
            };
            modal.printInspectionInterventionType = function (model) {
                if(model) {
                	var insIntType = $linq(apiAdmin.inspectionInterventionTypes).firstOrDefault(undefined, "x => x.id == '"+model+"'");
                	if(!insIntType && model && model.id){
                		insIntType = $linq(apiAdmin.inspectionInterventionTypes).firstOrDefault(undefined, "x => x.id == '"+model.id+"'");
                	}
                	if(insIntType){
                    	return $filter('translate')(insIntType.name);
                    }else{
                    	return "";
                    }
                } else {
                    return "";
                }
            };
            AnnexaModalFactory.showModal('modaClassificationEdit', modal);
        }

        $scope.deleteClassification = function(classification) {
            var dlg = dialogs.confirm(
                $filter('translate')('global.commonAdmin.literals.deleteClassification'),
                $filter('translate')('global.commonAdmin.literals.confirmDeleteClassificationMessage'),
                {
                    animation: true,
                    backdrop: 'static',
                    keyboard: true,
                    size: ''
                }
            );

            dlg.result.then(function (btn) {
                AdminFactory.deleteClassification(classification)
                    .then(function (data) {
                        $scope.archiveClassifications = CommonService.getNestableData(GlobalDataFactory.archiveClassifications, 'archiveClassification', 'orderView');
                        if(GlobalDataFactory.archiveClassifications){
                        	GlobalDataFactory.archiveClassifications.length = 0;
                        }else{
                        	GlobalDataFactory.archiveClassifications = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=ArchiveClassification')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=ArchiveClassification')
                        }
                        GlobalDataFactory.loadGlobalData().then(function (data) {
                        	$scope.archiveClassifications = CommonService.getNestableData(GlobalDataFactory.archiveClassifications, 'archiveClassification', 'orderView');
                        }).catch(function (error) {});
                }).catch(function (error) {
                    var err = dialogs.error(
                        $filter('translate')('DIALOGS_ERROR_MSG'),
                        $filter('translate')(error.data.message),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: ''
                        }
                    );
                });
            }, function (btn) {
            });
        }

        $scope.updateClassification = function(val, prop) {
            var okUpdate = true;
            if(prop && prop != this.languageColumn && prop != 'inspectionIntervention' && prop != 'inspectionInterventionType' 
            		&& prop != 'closeExpireType' && prop != 'transferExpireType' && prop != 'archiveExpireType' && prop != 'closeExpireQuantity' 
            		&& prop != 'transferExpireQuantity' && prop != 'archiveExpireQuantity' && prop != 'automaticClose' && prop != 'automaticTransfer' 
            		&& prop != 'automaticArchive'){
                if(val) {
                    this.dataAux[prop] = val;
                    this.dataAux[prop] = val;
                }else{
               		okUpdate = false;
                }
            }else if(prop == Language.getActiveColumn()){
                var cont = 1;
                var column = "language";
                if($rootScope.app.languagedef){
                    angular.forEach($rootScope.app.languagedef, function (value2, key2) {
                        if (val && !val[column + cont]) {
                            okUpdate = false;
                        }
                        cont++;
                    });
                }
                this.dataAux.language1 = this.data.language1;
                this.dataAux.language2 = this.data.language2;
                this.dataAux.language3 = this.data.language3;
            }else if(prop == 'inspectionIntervention'){
            	this.dataAux[prop] = ((val && val.id != undefined)?val.id:undefined);
	            if(!this.dataAux.inspectionIntervention){
            		this.data.inspectionInterventionType = undefined;
            		this.dataAux.inspectionInterventionType = undefined;
            	}
            }else if(prop == 'inspectionInterventionType'){
            	if(this.dataAux.inspectionIntervention){
            		if(val && val.id){
            			this.dataAux[prop] = val.id;
            		}else{
            			this.dataAux[prop] = undefined;
            		}
            	}else{
            		this.dataAux[prop] = undefined;
            	}
            }else if(prop == 'closeExpireType' || prop == 'transferExpireType' || prop == 'archiveExpireType'){
        		if(val && val.id){
        			this.dataAux[prop] = val.id;
        		}else{
        			this.dataAux[prop] = undefined;
        		}
            }else if(prop == 'closeExpireQuantity' || prop == 'transferExpireQuantity' || prop == 'archiveExpireQuantity'){
            	this.dataAux[prop] = val;
            }else if(prop == 'automaticClose' || prop == 'automaticTransfer' || prop == 'automaticArchive'){
            	this.dataAux[prop] = ((val && val.id != undefined)?val.id:undefined);
            }
            if(okUpdate) {
                var result = $scope.saveClassification(this.dataAux, undefined, undefined, this);
                if (result) {
                    return result;
                }
            }else{
                return $filter('translate')('global.validation.required');
            }
        }

		$scope.updateEMGDEClassification = function(val, prop, modal) {
			if(modal && modal.close && this.object){
				this.object[prop] = val;
				$scope.saveClassification(this.object, undefined, modal);
			}
		}
        
        $scope.saveClassification = function (classification, deferred, modalEmgde, modalUpdate) {
            var classificationModel = classification;
            var isNew = false;
            var modal = undefined;

            if(classification.hasOwnProperty('annexaFormly')) {
                modal = this;
                classificationModel = classification.annexaFormly.model.row1;
                if(!classificationModel.inspectionIntervention){
                	classificationModel.inspectionInterventionType = undefined;
                }
                isNew = true;
				var fieldEMGDE = $linq(modal.annexaFormly.fields[1].fieldGroup).firstOrDefault(undefined, "x=> x.key == 'emgdeAC'");
				if(fieldEMGDE && fieldEMGDE.data && fieldEMGDE.data.object){
					if(fieldEMGDE.data.object.emgdeSecurityLevel){
						classificationModel.emgdeSecurityLevel = fieldEMGDE.data.object.emgdeSecurityLevel;
					}
					if(fieldEMGDE.data.object.emgdeAccesType){
						classificationModel.emgdeAccesType = fieldEMGDE.data.object.emgdeAccesType;
					}
					if(fieldEMGDE.data.object.emgdeLimitCause){
						classificationModel.emgdeLimitCause = fieldEMGDE.data.object.emgdeLimitCause;
					}
					if(fieldEMGDE.data.object.emgdeLegalLimitCause){
						classificationModel.emgdeLegalLimitCause = fieldEMGDE.data.object.emgdeLegalLimitCause;
					}
					if(fieldEMGDE.data.object.emgdeReuseCondition){
						classificationModel.emgdeReuseCondition = fieldEMGDE.data.object.emgdeReuseCondition;
					}
					if(fieldEMGDE.data.object.emgdeValueType){
						classificationModel.emgdeValueType = fieldEMGDE.data.object.emgdeValueType;
					}
					if(fieldEMGDE.data.object.emgdeRatingTerms){
						classificationModel.emgdeRatingTerms = fieldEMGDE.data.object.emgdeRatingTerms;
					}
					if(fieldEMGDE.data.object.emgdeSecundaryValue){
						classificationModel.emgdeSecundaryValue = fieldEMGDE.data.object.emgdeSecundaryValue;
					}
					if(fieldEMGDE.data.object.emgdeOpinionType){
						classificationModel.emgdeOpinionType = fieldEMGDE.data.object.emgdeOpinionType;
					}
					if(fieldEMGDE.data.object.emgdeOpinionAction){
						classificationModel.emgdeOpinionAction = fieldEMGDE.data.object.emgdeOpinionAction;
					}
					if(fieldEMGDE.data.object.emgdeOpinionActionTerms){
						classificationModel.emgdeOpinionActionTerms = fieldEMGDE.data.object.emgdeOpinionActionTerms;
					}
					if(fieldEMGDE.data.object.emgdeTransferArchive){
						classificationModel.emgdeTransferArchive = fieldEMGDE.data.object.emgdeTransferArchive;
					}
					if(fieldEMGDE.data.object.emgdeTransferTerms){
						classificationModel.emgdeTransferTerms = fieldEMGDE.data.object.emgdeTransferTerms;
					}
					if(fieldEMGDE.data.object.emgdeEssentialDocument){
						classificationModel.emgdeEssentialDocument = fieldEMGDE.data.object.emgdeEssentialDocument;
					}				
				}
            }
            AdminFactory.newClassification(classificationModel)
                .then(function(data) {
                    $scope.archiveClassifications = CommonService.getNestableData(GlobalDataFactory.archiveClassifications, 'archiveClassification', 'orderView');
                    if(GlobalDataFactory.archiveClassifications){
                    	GlobalDataFactory.archiveClassifications.length = 0;
                    }else{
                    	GlobalDataFactory.archiveClassifications = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=ArchiveClassification')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=ArchiveClassification')
                    }
                    GlobalDataFactory.loadGlobalData().then(function (data) {
                    	$scope.archiveClassifications = CommonService.getNestableData(GlobalDataFactory.archiveClassifications, 'archiveClassification', 'orderView');
                    }).catch(function (error) {});
                    
                    if(isNew && modal) {
                        modal.close();
                    }else if(modalEmgde && modalEmgde.close){
						modalEmgde.close();
					}
                    if (deferred) {
                    	deferred.resolve(true);
                    }
                }).catch(function (error) {
                    var errorMSG = 'DIALOGS_ERROR_MSG';
                    if(error.data && error.data.message){
                        errorMSG = error.data.message;
                    }
                    if (deferred) {
                		deferred.resolve(false);
                	}
                    if(isNew && modal) {
                        modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.alerts.push({msg: errorMSG});
                    }else{
						if(modalEmgde && modalEmgde.close){
							modalEmgde.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
						} else if(modalUpdate && modalUpdate.alerts) {
							modalUpdate.alerts.push({ msg: $filter('translate')(errorMSG) });
						} else{
	                        return $filter('translate')(errorMSG);
						}
                    }
            });
        }

        $scope.treeOptions = {
            beforeDrop : function (e) {
            	var deferred = $q.defer();
            	DialogsFactory.confirm('global.commonAdmin.literals.moveClassification', 'global.commonAdmin.literals.confirmMoveClassification').then(function (dataAux) {
	                var sourceValue = e.source.nodeScope.$modelValue.archiveClassification;
	                var sourceParentValue = null;
	                if( e.source.nodeScope.$modelValue.archiveClassification.parent != null &&  e.source.nodeScope.$modelValue.archiveClassification.parent != undefined) {
	                    sourceParentValue = e.source.nodeScope.$modelValue.archiveClassification.parent.id;
	                }
	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.archiveClassification: null;
	                if(destValue != null && destValue != undefined) {
                    	sourceValue.parent = destValue;
	                }else {
	                    sourceValue.parent = null;
	                }
	                if(e.dest != null){
	                	sourceValue.orderView = e.dest.index+1;
	                }
	                $scope.saveClassification(sourceValue, deferred);
            	}).catch(function (data) {
                	deferred.reject(false);
	            });  
            	
            	return deferred.promise;    
            }
        };


        $scope.exportClassifications = function(){
            $rootScope.showLoadingdivSignin = true;
            try {
                var cells = ['code',$scope.langColumn];
                var fileName = "archiveClassification.xls";
                var xlsFile = HelperService.exportToExcel($scope.archiveClassifications, 'nodes', cells, 'archiveClassification', fileName, window, 'global.literals.classificationBox');
                $rootScope.showLoadingdivSignin = false;
            } catch(e) {
                $rootScope.showLoadingdivSignin = false;
                console.log(e.message);
            }
        }
        
        $scope.reloadTableArchiveClassifications = function(){
        	//recarga la tabla del cuadro de clasificaci�n
			$scope.archiveClassifications = CommonService.getNestableData(GlobalDataFactory.archiveClassifications, 'archiveClassification', 'orderView');
			if(GlobalDataFactory.archiveClassifications){
				GlobalDataFactory.archiveClassifications.length = 0;
			}else{
				GlobalDataFactory.archiveClassifications = [];
			}
			if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=ArchiveClassification')){
				CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=ArchiveClassification')
			}
			GlobalDataFactory.loadGlobalData().then(function (data) {
				$scope.archiveClassifications = CommonService.getNestableData(GlobalDataFactory.archiveClassifications, 'archiveClassification', 'orderView');
			}).catch(function (error) {});
        }
        
        $scope.importClassification = function () {  
        	AdminFactory.importDataAdmin($scope, "ARCHIVE_CLASSIFICATION", undefined);
        }
        
    }]);
