/**
 * Created by osirvent on 05/06/2015.
 */
angular.module('annexaApp')
    .factory('ModalAltaPerfil',['$http', '$translate', '$compile', '$state', 'apiAdmin', '$filter', 'CommonAdminService', function($http, $translate, $compile, $state, apiAdmin, $filter, CommonAdminService) {
        var ModalAltaPerfil = function($scope, module) {
            $scope.altaPerfil = true;
            $scope.newProfile = {text:[]};

            $scope.editableProfile = true;
            $scope.errorAltaPerfil = false;
            $scope.errorAltaPerfilDescripcio = "";
        };

        //-- Inici funcions modals --//
        ModalAltaPerfil.prototype.addPerfil = function ($scope, id, editable){
            $scope.errorAltaPerfil = false;
            $scope.errorAltaPerfilDescripcio = "";
            $scope.editableProfile = editable;
            if (id) {
                CommonAdminService.getProfileById(id).then(function (dataSearch) {
                    $scope.newProfile = dataSearch;
                    if ($scope.newProfile.createdDate && $scope.newProfile.createdDate.time) {
                        $scope.newProfile.createdDate = new Date($scope.newProfile.createdDate.time);
                    }
                });
            }
            else {
                $scope.newProfile = {};
            }
        };

        //-- Inici funcions modals --//

        ModalAltaPerfil.prototype.buttonNewDisabled = function($scope){
            var disabled = false;

            if($scope.languages == null || $scope.languages == undefined ){
                disabled = true;
            }else {
                for (var i = 0; i < $scope.languages.length; i++) {
                    if ($scope.newProfile[$scope.languages[i].column] == null || $scope.newProfile[$scope.languages[i].column] == undefined || $scope.newProfile[$scope.languages[i].column] == '') {
                        disabled = true;
                    }
                }
            }
            return disabled;
        }

        ModalAltaPerfil.prototype.eliminarDadesScope = function ($scope){
        };

        ModalAltaPerfil.prototype.crearPerfil = function ($scope){
            if($scope.newProfile) {
                CommonAdminService.insertOrUpdateProfile($scope.newProfile).then(function (dataUpdate) {
                    var aux = 0;
                    if (dataUpdate.id) {
                        $scope.tableProfiles.reloadInternalData(true);
                        $('#newProfileModal').modal('hide');
                    } else {
                        $scope.errorAltaPerfil = true;
                        $scope.errorAltaPerfilDescripcio = "Error creació Profile";
                        $('#errorAltaPerfilDiv').show();
                    }
                }).catch(function () {
                    $scope.errorAltaPerfil = true;
                    $scope.errorAltaPerfilDescripcio = "Error creació Profile";
                    $('#errorAltaPerfilDiv').show();
                });
            }
            else {
                $scope.errorAltaPerfil = true;
                $scope.errorAltaPerfilDescripcio = "El camp Tipus es obligatori.";
                $('#errorAltaPerfilDiv').show();
            }
        };

        function callbackInstance(json) {
            console.log(json);
        };

        //-- Fi funcions modals--//
        return ModalAltaPerfil;
    }]);