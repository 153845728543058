/**
 * Created by aguerrero on 03/04/2016.
 */

angular
    .module('annexaApp')
    .controller('TramClassificationAdminController', ['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'ErrorFactory', function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, ErrorFactory) {

        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.tramClassificationNew);
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewTramClassification', modal, $scope.saveTramClassification, false);
        }

        $scope.saveTramClassification = function (tramClassification) {
            var modal = this;

            var model = {
                language1: tramClassification.annexaFormly.model.modal_body.language1,
                language2: tramClassification.annexaFormly.model.modal_body.language2 ? tramClassification.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: tramClassification.annexaFormly.model.modal_body.language3 ? tramClassification.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                code: tramClassification.annexaFormly.model.modal_body.code
            }

            RestService.insert('./api/tramClassification', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    if(GlobalDataFactory.tramClassifications){
                    	GlobalDataFactory.tramClassifications.length = 0;
                    }else{
                    	GlobalDataFactory.tramClassifications = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('tramClassifications')){
                    	CacheFactory.get('globalDataCache').remove('tramClassifications')
                    }
                    GlobalDataFactory.loadGlobalData();
                    modal.close();
                }).catch(function (error) {
                	modal.alerts.push({ msg: ErrorFactory.getErrorMessage('tramClassification', 'create', error.data) });
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('TramClassification', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = CommonAdminModals.tramClassificationEdit;
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();

                    modal.updateTramClassification = $scope.updateTramClassification;

                    AnnexaModalFactory.showModal('modalTramClassificationEdit', modal);
                });
        }

        $scope.updateTramClassification = function(val, prop) {
            var model = this.data;
            var modal = this;
            
            if(prop == "code") {
            	model[prop] = val;
            } else {
                model = val;
            }
            
            RestService.update('./api/tramClassification/' + model.id, model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if(GlobalDataFactory.tramClassifications){
                    	GlobalDataFactory.tramClassifications.length = 0;
                    }else{
                    	GlobalDataFactory.tramClassifications = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('tramClassifications')){
                    	CacheFactory.get('globalDataCache').remove('tramClassifications')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function (error) {
                	modal.alerts.push({ msg: ErrorFactory.getErrorMessage('tramClassification', 'create', error.data) });
            });
        }
    }]);
