/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('EntityHolidaysController',['$scope', '$filter', '$http', '$q', '$rootScope', 'Language', 'CommonAdminService', 'uiCalendarConfig', 'DialogsFactory', 'AdminFactory', 'RestService',
        function($scope, $filter, $http, $q, $rootScope, Language, CommonAdminService, uiCalendarConfig, DialogsFactory, AdminFactory, RestService) {

            $scope.showLoadingdiv = false;

            $calendar = $('[ui-calendar]');

            var date = new Date();
            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            $scope.events = [];
            if(AdminFactory.entityEvents && AdminFactory.entityEvents.holidays){
                _.forEach(AdminFactory.entityEvents.holidays, function(entityEvent){
                    var entityEventDate = new Date(entityEvent);
                    $scope.events.push({title: '',start: new Date(entityEventDate.getFullYear(), entityEventDate.getMonth(), entityEventDate.getDate()), allDay: true});
                });
            }
            $scope.eventSources = [$scope.events];

            var indexEventInDate = function(moment, isId){
                if(isId){
                    return $linq($scope.events).indexOf("x => x._id == "+moment);
                }else{
                    var newDate = moment;
                    return $linq($scope.events).indexOf(function(x){
                        if(x.start){
                            if(x.start.getFullYear() ==  newDate.getFullYear() && x.start.getMonth() == newDate.getMonth() && x.start.getDate() == newDate.getDate()){
                                return true;
                            }
                        }
                        return false;
                    });

                }
            }

            $scope.uiConfig = {
                calendar: {
                    height: 450,
                    editable: true,
                    header: {
                        left: '',
                        center: 'title',
                        right: 'today prev,next'
                    },
                    eventClick: function(date, jsEvent, view) {
                        var indexOf = indexEventInDate(date._id, true);
                        if(indexOf > -1){
                            DialogsFactory.confirm('global.literals.deleteEventEntity', 'global.literals.confirmDeleteEventEntity').then(function () {
                                var eventsAux = angular.copy($scope.events);
                                var entityAux = angular.copy(AdminFactory.entityEvents);
                                eventsAux.splice(indexOf, 1);
                                entityAux.holidays.length = 0;
                                _.forEach(eventsAux, function(event){
                                    var dateStart = new Date(event.start);
                                    entityAux.holidays.push(new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate()));
                                });
                                RestService.update('./api/entities/' + entityAux.id, entityAux).then(function(data) {
                                    returnUpdate(data);
                                }).catch(function (error) {
                                    DialogsFactory.error(error.data.message, $filter('translate')('DIALOGS_ERROR_MSG'));
                                });
                            }).catch(function () {
                                //Empty
                            });
                        }
                    },
                    dayClick: function(date, allDay, jsEvent, view) {
                        var eventsAux = angular.copy($scope.events);
                        var entityAux = angular.copy(AdminFactory.entityEvents);
                        var newDate = new Date(date);
                        var indexOf = indexEventInDate(newDate, false);
                        if(indexOf > -1) {
                            DialogsFactory.confirm('global.literals.deleteEventEntity', 'global.literals.confirmDeleteEventEntity').then(function () {
                                eventsAux.splice(indexOf, 1);
                                entityAux.holidays.length = 0;
                                _.forEach(eventsAux, function(event){
                                    var dateStart = new Date(event.start);
                                    entityAux.holidays.push(new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate()));
                                });
                                RestService.update('./api/entities/' + entityAux.id, entityAux).then(function(data) {
                                    returnUpdate(data);
                                }).catch(function (error) {
                                    DialogsFactory.error(error.data.message, $filter('translate')('DIALOGS_ERROR_MSG'));
                                });
                            }).catch(function () {
                                //Empty
                            });
                        }else{
                            DialogsFactory.confirm('global.literals.addEventEntity', 'global.literals.confirmAddEventEntity').then(function () {
                                eventsAux.push({title: '',start: new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()),allDay: true});
                                entityAux.holidays.length = 0;
                                _.forEach(eventsAux, function(event){
                                    var dateStart = new Date(event.start);
                                    entityAux.holidays.push(new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate()));
                                });
                                RestService.update('./api/entities/' + entityAux.id, entityAux).then(function(data) {
                                    returnUpdate(data);
                                }).catch(function (error) {
                                    DialogsFactory.error(error.data.message, $filter('translate')('DIALOGS_ERROR_MSG'));
                                });
                            }).catch(function () {
                                //Empty
                            });
                        }
                    },
                    eventDrop: function(date, jsEvent, view) {
                        var eventsAux = angular.copy($scope.events);
                        var entityAux = angular.copy(AdminFactory.entityEvents);
                        DialogsFactory.confirm('global.literals.modifyEventEntity', 'global.literals.confirmModifyEventEntity').then(function () {
                            var indexOf = indexEventInDate(date._id, true);
                            var indexOfNext = indexEventInDate(date.start._d, false);
                            if(indexOfNext == -1) {
                                if (indexOf > -1) {
                                    eventsAux.splice(indexOf, 1);
                                }
                                var newDate = new Date(date.start);
                                eventsAux.push({title: '',start: new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()),allDay: true});
                                entityAux.holidays.length = 0;
                                _.forEach(eventsAux, function(event){
                                    var dateStart = new Date(event.start);
                                    entityAux.holidays.push(new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate()));
                                });
                                RestService.update('./api/entities/' + entityAux.id, entityAux).then(function(data) {
                                    returnUpdate(data);
                                }).catch(function (error) {
                                    DialogsFactory.error(error.data.message, $filter('translate')('DIALOGS_ERROR_MSG'));
                                });
                            }else{
                                $scope.events.splice(indexOf, 1);
                                $scope.events.push({title: '',start: new Date(date.start._i.getFullYear(), date.start._i.getMonth(), date.start._i.getDate()), allDay: true});
                                DialogsFactory.error('global.literals.confirmErrorModifyEventEntity', $filter('translate')('global.literals.errorModifyEventEntity'));
                            }
                        }).catch(function () {
                            var indexOf = indexEventInDate(date._id, true);
                            if(indexOf > -1) {
                                $scope.events.splice(indexOf, 1);
                                $scope.events.push({title: '',start: new Date(date.start._i.getFullYear(), date.start._i.getMonth(), date.start._i.getDate()), allDay: true});
                            }
                        });
                    }
                }
            };

            var returnUpdate = function(entityAux){
                $scope.events.length = 0;
                AdminFactory.entityEvents.holidays.length = 0;
                if(entityAux && entityAux.holidays){
                    _.forEach(entityAux.holidays, function(entityEvent){
                        AdminFactory.entityEvents.holidays.push(entityEvent);
                        var entityEventDate = new Date(entityEvent);
                        $scope.events.push({title: '',start: new Date(entityEventDate.getFullYear(), entityEventDate.getMonth(), entityEventDate.getDate()), allDay: true});
                    });
                }
            };
        }]);