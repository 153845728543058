/**
 * Created by apop on 24/10/2017.
 */
angular
    .module('annexaApp')
    .controller('RegisterDocumentTypeController', ['$scope', '$filter','$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory','AnnexaModalFactory', 'RestService', 'Language', 'RegFactory', 'GlobalDataFactory', 'CacheFactory', function($scope, $filter, $rootScope, CommonAdminModals, AnnexaFormlyFactory, AnnexaModalFactory, RestService, Language, RegFactory, GlobalDataFactory, CacheFactory) {
        $scope.additionalColumns = [
            { id: 'entryType', column: new PartialLanguageColumn($filter, 'global.literals.regType', 'global.reg.literals.'), sortable: false },
            { id: 'code', type: 'text', title: $filter('translate')('global.literals.codeType'), sortable: true }
        ]

        $scope.newElement = function () {
            var modal = CommonAdminModals.registerEntryDocumentType;
            modal.title = 'global.literals.regType';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal("modalRegisterDocumentType", modal, $scope.saveDocumentType, false);
        }

        $scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
            { id: 'code', type: 'text', order: 0, label: 'global.literals.codeType' }
        ];

        $scope.saveDocumentType = function(registerDocumentType) {
            var modal = this;

            var model = {
                language1: registerDocumentType.annexaFormly.model.modal_body.language1,
                language2: registerDocumentType.annexaFormly.model.modal_body.language2 ? registerDocumentType.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: registerDocumentType.annexaFormly.model.modal_body.language3 ? registerDocumentType.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                entryType: registerDocumentType.annexaFormly.model.modal_body.entryType != undefined ? registerDocumentType.annexaFormly.model.modal_body.entryType : RestService.insert('./api/reg/register_document_type', modal),
                code: registerDocumentType.annexaFormly.model.modal_body.code
            };

            RestService.insert('./api/reg/register_document_type', model)
                .then(function() {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if(GlobalDataFactory.registerEntryDocumentTypesInput){
                    	GlobalDataFactory.registerEntryDocumentTypesInput.length = 0;
                    }else{
                    	GlobalDataFactory.registerEntryDocumentTypesInput = [];
                    }
                    if(GlobalDataFactory.registerEntryDocumentTypesOutput){
                    	GlobalDataFactory.registerEntryDocumentTypesOutput.length = 0;
                    }else{
                    	GlobalDataFactory.registerEntryDocumentTypesOutput = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_document_type/entry_type/OUTPUT')){
                    	CacheFactory.get('globalDataCache').remove('./api/reg/register_entry_document_type/entry_type/OUTPUT')
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_document_type/entry_type/INPUT')){
                    	CacheFactory.get('globalDataCache').remove('./api/reg/register_entry_document_type/entry_type/INPUT')
                    }
                    GlobalDataFactory.loadGlobalData();
                    modal.close();
                }).catch(function (error) {
                	console.error(error);
                	if(error.data.message.indexOf("constraint [code]") !== -1) {
                		modal.alerts.push({ msg: $filter('translate')('global.errors.errorRegisterDocumentTypeCodeExist') });
                    } else {
                    	modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
                    }
            });
        }

        // Cuando le damos al boton VER
        $scope.viewElement = function(id) {
            RestService.findOne('RegisterEntryDocumentType', id)
                .then(function(data) {
                    var modal = angular.copy(CommonAdminModals.RegisterEntryDocumentTypeEdit);
                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.entryTypeName = 'name';
                    modal.entryTypes = [
                        { id: 'INPUT', name: $filter('translate')('global.literals.INPUT') },
                        { id: 'OUTPUT', name: $filter('translate')('global.literals.OUTPUT') }
                    ]
                    modal.updateRegisterEntryDocumentType = $scope.updateRegisterEntryDocumentType;

                    modal.printDocumentType = function(modelValue) {
                        if(modelValue == 'INPUT') {
                            return $filter('translate')('global.literals.INPUT');
                        } else {
                            return $filter('translate')('global.literals.OUTPUT');
                        }
                    }

                    AnnexaModalFactory.showModal('modalRegisterEntryDocumentTypeEdit', modal);
                });
        }

        $scope.updateRegisterEntryDocumentType = function(val, prop) {
        	var modal = this;
            var model = modal.data;

            if(prop == 'entryType') {
                model.entryType = val.id;
            }
            else if( prop === 'code' ){
                model.code = val;
            }
            else {
                model = val;
            }

            RestService.update('./api/reg/register_document_type/' + model.id, model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if(GlobalDataFactory.registerEntryDocumentTypesInput){
                    	GlobalDataFactory.registerEntryDocumentTypesInput.length = 0;
                    }else{
                    	GlobalDataFactory.registerEntryDocumentTypesInput = [];
                    }
                    if(GlobalDataFactory.registerEntryDocumentTypesOutput){
                    	GlobalDataFactory.registerEntryDocumentTypesOutput.length = 0;
                    }else{
                    	GlobalDataFactory.registerEntryDocumentTypesOutput = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_document_type/entry_type/OUTPUT')){
                    	CacheFactory.get('globalDataCache').remove('./api/reg/register_entry_document_type/entry_type/OUTPUT')
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_document_type/entry_type/INPUT')){
                    	CacheFactory.get('globalDataCache').remove('./api/reg/register_entry_document_type/entry_type/INPUT')
                    }
                    GlobalDataFactory.loadGlobalData();
                    modal.alerts = [];
                }).catch(function (error) {
                	console.error(error);
                	if(error.data.message.indexOf("constraint [code]") !== -1) {
                		modal.alerts.push({ msg: $filter('translate')('global.errors.errorRegisterClassificationAcronymExist') });
                    } else {
                    	modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
                    }
            });
        };

    }])