angular
    .module('annexaApp')
        .controller('QueryTablesController', ['$scope', '$state', '$filter', 'HeaderService','$rootScope', 'CommonService', 'AdminFactory', 'Language', 'AnnexaFormlyFactory', 'CommonAdminModals', 'AnnexaModalFactory', 'dialogs', 'HelperService',
            function ($scope, $state, $filter, HeaderService, $rootScope, CommonService, AdminFactory, Language, AnnexaFormlyFactory, CommonAdminModals, AnnexaModalFactory, dialogs, HelperService) {
                $rootScope.loading(false);
                $scope.queryTablesInformation = angular.copy(AdminFactory.queryTablesInformation);
                $scope.langColumn = Language.getActiveColumn();

                HeaderService.onChangeState($scope, function (message) {
                    if(message.state.name == 'annexa.queryTables') {
                        $rootScope.subHeadButtons = [];
                    }
                });

                HeaderService.changeState($state.current);

                $scope.toggleAction = function (scope) {
                    if(scope && scope.$parent && scope.$parent.$modelValue && scope.$parent.$modelValue.name &&  scope.$parent.collapsed){
                        var table = $linq($scope.queryTablesInformation).firstOrDefault(undefined, "x => x.name == '"+scope.$parent.$modelValue.name+"'");
                        if(table && table.columns && table.columns.length < 2){
                            AdminFactory.getQueryColumnsInformation(scope.$parent.$modelValue.name, $rootScope.toStateParams.objectId).then(function(data){
                                if(data && data.length > 0) {
                                    if (table.columns) {
                                        table.columns.length = 0;
                                    } else {
                                        table.columns = [];
                                    }
                                    _.forEach(data, function (column) {
                                        table.columns.push(column);
                                    });
                                }
                            }).catch(function(error){
                            });
                        }
                    }
                    scope.toggle();
                };

                $scope.treeOptions = {
                };

            }]);