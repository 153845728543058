/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('AdminController', ['$scope', 'apiAdmin', 'RestService', 'HeaderService', '$rootScope', '$state', function ($scope, apiAdmin, RestService, HeaderService, $rootScope, $state) {
        $scope.showLoadingdiv = false;
        $scope.moduleActions = {};
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.admin') {
                $rootScope.subHeadButtons = [];
                $rootScope.subHeadTabs = [];
            }
        });

        HeaderService.changeState($state.current);
        
        RestService.findAll('Module', 'admin')
            .then(function(data) {
                var modules = JSOG.decode(data.data);
                if(modules && modules.length > 0){
                    for(var i=0; i<modules.length; i++){
                        var module = modules[i];
                        if(module && module.serviceName && module.serviceName.toUpperCase() == apiAdmin.moduleId.toUpperCase()){
                            $scope.moduleActions = module.moduleActions;
                            break;
                        }
                    }
                }
            }).catch(function(error) {
                console.log(error);
        });
    }])
    .controller('AuditController',['$scope', '$stateParams',function ($scope, $stateParams) {
        if($stateParams && $stateParams.objectId){
            $scope.objectId = $stateParams.objectId;
        }
    }]);
