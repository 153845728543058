/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('TransactionTypeController',['$q', '$http','$scope', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'AnnexaModalFactory', 'apiAdmin', 'AdminFactory', 'TramFactory','dialogs', 'DialogsFactory', 'GlobalDataFactory', 'CommonService', 'HelperService',
        function($q, $http, $scope, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService, AnnexaModalFactory, apiAdmin, AdminFactory, TramFactory, dialogs, DialogsFactory, GlobalDataFactory, CommonService, HelperService) {
        $scope.showLoadingdiv = false;

        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');


        $scope.dtDefTransactionTypes = new TableData('tram','TransactionType','./api/common/byfiler','rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>','orderView',[[1, 'asc']]);

        var transactionTypeNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
        var transactionTypeColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                   new ActionButton('global.literals.see','editTransactionType([data])','fa-eye'),
                                                   [new ActionButton('global.literals.remove','deleteTransactionType([data])')]);

        $scope.tableTransactionTypes = {
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn('orderView').withOption('width', '5%').withTitle($filter('translate')('global.literals.order')),
                DTColumnBuilder.newColumn(transactionTypeNameColumn.getColumn()).withTitle(transactionTypeNameColumn.getTitle()),
                DTColumnBuilder.newColumn('id').withOption('width', '12%').withOption('className', 'text-center').withTitle(transactionTypeColumn.getTitle()).notSortable().renderWith(transactionTypeColumn.getRender)
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux: { langColumn: Language.getActiveColumn()},
            reloadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = { langColumn: Language.getActiveColumn()};
                this.table.reloadData(function callbackInstance(json){}, resetPaging);
            }
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.transaction_type') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-transactionType','fa fa-plus','global.commonAdmin.modal.transactionType.titleNew',undefined,'openNewTransactionType'),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-transactionType','fa fa-upload','global.literals.import',undefined,'importTransactionType')
                ];
            }
        })

        HeaderService.changeState($state.current);

        $scope.saveTransactionType = function() {
            var data = this.annexaFormly.model.modal_body;
			var dataColumn1 = ((data && data.column1)?angular.copy(data.column1):{});
            if(dataColumn1.phase && !dataColumn1.phase.id){
                dataColumn1.phase = {id:dataColumn1.phase};
            }
            if(dataColumn1.tramitationType && !dataColumn1.tramitationType.id){
                dataColumn1.tramitationType = {id:dataColumn1.tramitationType};
            }
            if(dataColumn1.active_1 != undefined) {
            	dataColumn1.active = dataColumn1.active_1;
            }
			var dataCustomFields = [];
			if(this.annexaFormly.fields && this.annexaFormly.fields.length > 0 && this.annexaFormly.fields[0].fieldGroup
			&& this.annexaFormly.fields[0].fieldGroup.length > 1 &&  this.annexaFormly.fields[0].fieldGroup[1].fieldGroup 
			&&  this.annexaFormly.fields[0].fieldGroup[1].fieldGroup.length > 0 && this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data
			&& this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.customFields){
				dataCustomFields = angular.copy(this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.customFields);
			}
			var transactionType = {transactionType: dataColumn1, customFields:dataCustomFields};
			var modal = this;
            if(!(dataColumn1.transactionSubtype == 0 || dataColumn1.transactionSubtype == 1 || dataColumn1.transactionSubtype == 7) && dataColumn1.startType){
            	DialogsFactory.error($filter('translate')('global.literals.transactionSubtypeNotInitial'));
            }else if(dataColumn1.transactionSubtype != 0 && dataColumn1.transactionSubtype != 2 && dataColumn1.transactionSubtype != 3 && dataColumn1.transactionSubtype != 10 && dataColumn1.finallyType){
                DialogsFactory.error($filter('translate')('global.literals.transactionSubtypeNotFinal'));
            }else{
	            CommonAdminService.insertOrUpdateTransactionType(transactionType).then(function(data) {
	                modal.close();
	                $scope.doSearchFilter();
	            }).catch(function(error) {
	                console.log("ERROR");
	            });
            }
        };
        
        
        $scope.saveTransactionTypeEdit = function(modify, property) {
            var modal = this;
            var valid = true;

            if(property == 'language1') {
                var languages = $rootScope.app.languagedef;
                var column = "language";
                if (languages) {
                    angular.forEach(languages, function (value2, key2) {
                        if (modal.data && !modal.data[value2.column]) {
                            valid = false;
                        }
                    });
                }
            } else {
                modal.data[property] = modify;
            }
            if(!(modal.data.transactionSubtype == 0 || modal.data.transactionSubtype == 1 || modal.data.transactionSubtype == 7) && modal.data.startType){
            	return $filter('translate')('global.literals.transactionSubtypeNotInitial');
            }else if(modal.data.transactionSubtype != 0 && modal.data.transactionSubtype != 2 && modal.data.transactionSubtype != 3 && modal.data.transactionSubtype != 10 && modal.data.finallyType){
            	return $filter('translate')('global.literals.transactionSubtypeNotFinal');
            }else{
	            if(valid) {
	                CommonAdminService.insertOrUpdateTransactionType({transactionType:modal.data})
	                    .then(function (data) {
	                        modal.transactionTypeAux = {
	                            language1: modal.data.language1,
	                            language2: modal.data.language2,
	                            language3: modal.data.language3,
	                            tramitationType: modal.data.tramitationType,
	                            finallyType: modal.data.finallyType,
	                            startType: modal.data.startType,
	                            countDays: modal.data.countDays,
	                            phase: modal.data.phase,
	                            days: modal.data.days,
	                            transactionSubtype: modal.data.transactionSubtype,
	                            expireType: modal.data.expireType,
	                            allowUserChangeMaxDays:modal.data.allowUserChangeMaxDays,
	                            allowUserChangeCountDays:modal.data.allowUserChangeCountDays
	                        };
	                    $scope.doSearchFilter();
	                }).catch(function (error) {
	                    console.log("ERROR");
	                });
	            }else{
	                return $filter('translate')('global.validation.required');
	            }
            }
        };
        $scope.cancelTransactionTypeEdit = function() {
            var modal = this;
            if(modal && modal.transactionTypeAux){
                modal.data.language1 = modal.transactionTypeAux.language1;
                modal.data.language2 = modal.transactionTypeAux.language2;
                modal.data.language3 = modal.transactionTypeAux.language3;
                modal.data.tramitationType = modal.transactionTypeAux.tramitationType;
                modal.data.finallyType = modal.transactionTypeAux.finallyType;
                modal.data.startType = modal.transactionTypeAux.startType;
                modal.data.countDays = modal.transactionTypeAux.countDays;
                modal.data.phase = modal.transactionTypeAux.phase;
                modal.data.days = modal.transactionTypeAux.days;
                modal.data.transactionSubtype = modal.transactionTypeAux.transactionSubtype;
                modal.data.expireType = modal.transactionTypeAux.expireType;
                modal.data.allowUserChangeMaxDays = modal.transactionTypeAux.allowUserChangeMaxDays;
                modal.data.allowUserChangeCountDays = modal.transactionTypeAux.allowUserChangeCountDays;
            }
        };

        $scope.deleteTransactionType = function(id) {
            if(id) {
                var dlg = dialogs.confirm(
                    $filter('translate')('global.literals.deleteTransactionType'),
                    $filter('translate')('global.literals.confirmDeleteTransactionTypeMessage'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );

                dlg.result.then(function (btn) {
                    CommonAdminService.deleteTransactionType(id).then(function (data) {
                        $scope.doSearchFilter();
                    }).catch(function (error) {
                        var err = dialogs.error(
                            $filter('translate')('DIALOGS_ERROR_MSG'),
                            $filter('translate')(error.data.message),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                    });
                }, function (btn) {
                });


            }
        };

        $scope.canEdit = true;
        
        $scope.editTransactionType = function(id) {
        	CommonAdminService.haveProcedureTransactionType(id)
				.then(function (data) {
					if(data) {
						$scope.canEdit = false;
					} else {
						$scope.canEdit = true;
					}
					var promises = [];
					promises.push(RestService.findOne('TransactionType', id));
					promises.push($http({url: './api/transactiontypes/transactionTypesCustomFields/'+id, method: 'GET'}));
                    promises.push(RestService.query('CustomField', 'findByOtherList', '{ in: true }'));
					$q.all(promises).then(function(data) {
		                    var modal = angular.copy(CommonAdminModals.transactionTypeEdit);
		                    var customFieldsType = [];
                            modal.yesNoList = apiAdmin.yesNoBoolean;
		                    modal.expirationTypeList = apiAdmin.expirationTypeList;
		                    modal.transactionSubtypesList = new SortedArray(apiAdmin.transactionSubtypes, 'order').sort();
		                    modal.data = JSOG.decode(data[0].data);
							var cfs = JSOG.decode(data[1].data);
							var cfsDisabled = JSOG.decode(data[2].data);
							angular.forEach(cfs, function (value, key) {
                                var customField = {
                                    customField: value.customField,
                                    id: value.id,
                                    delete: value.deleted,
                                    viewOrder:value.viewOrder,
                                    required: value.required,
                                    noEditable: value.noEditable,
                                    transactionType: value.transactionType,
                                    createdDate: value.createdDate,
                                    value: value.value
                                };
                                customField.requiredString = 'OPTIONAL';
                                if(value.required){
                                    customField.requiredString = 'REQUIRED';
                                }else if(value.noEditable) {
                                    customField.requiredString = 'NO_EDITABLE';
                                }
                                var aux = HelperService.findFirstElementWhere(((cfsDisabled)?cfsDisabled:[]), 'id', value.customField.id, -1);
                                if(aux != -1 && aux.disableElement){
                                    customField.customField.disableElement = true;
                                }
                                customFieldsType.push(customField);
                            });
							modal.customFields = customFieldsType;
		                    modal.transactionTypeAux = {language1:modal.data.language1, language2:modal.data.language2, language3:modal.data.language3,  finallyType:modal.data.finallyType, startType:modal.data.startType, countDays:modal.data.countDays, phase:modal.data.phase, days:modal.data.days, tramitationType: modal.data.tramitationType, transactionSubtype:modal.data.transactionSubtype, expireType:modal.data.expireType};
		                    modal.activeColumn = Language.getActiveColumn();
		                    modal.saveTransactionTypeEdit = $scope.saveTransactionTypeEdit;
		                    modal.cancelTransactioonTypeEdit = $scope.cancelTransactionTypeEdit;
		                    modal.tramitationPhases = AdminFactory.tramitationPhases;
		                    modal.tramitationTypes = GlobalDataFactory.dossierTramitationTypes;
		                    modal.canEdit = $scope.canEdit;
		                    modal.showTransactionSubtype = function(){
		                    	var subtypeSelected = modal.transactionTypeAux.transactionSubtype;
		                    	switch(subtypeSelected) {
		                    		case 0: return $filter('translate')('global.literals.standard');
		                    		case 1: return $filter('translate')('global.literals.proposalAgreement');
		                    		case 2: return $filter('translate')('global.literals.manageAgreement');
		                    		case 3: return $filter('translate')('global.literals.transferAgreement');
		                    		case 4: return $filter('translate')('global.literals.amendmentAgreement');
		                    		case 5: return $filter('translate')('global.literals.inspectionIntervention');
                    				case 6: return $filter('translate')('global.literals.inspectionDepartment');
                    				case 7: return $filter('translate')('global.literals.startInspectionFree');
                    				case 8: return $filter('translate')('global.literals.inspectionInterventionFree');
                    				case 9: return $filter('translate')('global.literals.inspectionDepartmentFree');
                    				case 10: return $filter('translate')('global.literals.manageAgreementFree');
                    				case 11: return $filter('translate')('global.literals.omission');
                    				case 12: return $filter('translate')('global.literals.reparation');
                    				case 13: return $filter('translate')('global.literals.omissionFree');
                    				case 14: return $filter('translate')('global.literals.reparationFree');
									case 15: return $filter('translate')('global.literals.manageAgreementOmission');
                    				default: return 'empty';
		                    	}
		                    }
		                    modal.printActive = function (model) {
		                        if(model) {
		                            return $filter('translate')('global.commonAdmin.modal.transactionType.transactionTypeActive');
		                        } else {
		                            return $filter('translate')('global.commonAdmin.modal.transactionType.transactionTypeNoActive');
		                        }
		                    };
		                    AnnexaModalFactory.showModal('modalTransactionTypeEdit', modal);
		                });
			}).catch(function (error) {
	            console.log("ERROR");
	        });
        }

       $scope.openNewTransactionType = function () {
           var modal = angular.copy(CommonAdminModals.transactionType);
           modal.annexaFormly.model = {};
           modal.annexaFormly.model.modal_body = { column1: {active_1: true}, column2:{customFields:[]} };
           modal.annexaFormly.options = {};
           modal.annexaFormly.options.formState = { readOnly: false};
           modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[2].templateOptions.labelProp = Language.getActiveColumn();
           modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[2].templateOptions.options = GlobalDataFactory.dossierTramitationTypes;
           modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].templateOptions.labelProp = Language.getActiveColumn();
           modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].templateOptions.options = AdminFactory.tramitationPhases;
           AnnexaFormlyFactory.showModal("modalTransactionType", modal, $scope.saveTransactionType, false);
       }

        $scope.doSearchFilter=function(queued) {
            $scope.tableTransactionTypes.reloadInternalData(true);
        }
        
        $scope.importTransactionType = function () {  
        	AdminFactory.importDataAdmin($scope, "TRANSACTION_TYPE", $scope.tableTransactionTypes);
        }
        
    }]);