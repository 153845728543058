/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('RoleInterestedController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', 'Language', 'AdminFactory', 'RestService', 'AnnexaModalFactory', 'DialogsFactory', function($scope, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, Language, AdminFactory, RestService, AnnexaModalFactory, DialogsFactory) {
        $rootScope.loading(false);

        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');

        $scope.dtDefRoleInterested = new TableData('tram', 'RoleInterested', './api/common/byfiler').setSort([[1, 'asc']]).setSortName(Language.getActiveColumn());

        var RolesNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn())
        var RolesActionColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                  new ActionButton('global.literals.see','viewRole([data])','fa-eye'),
                                                  [new ActionButton('global.literals.remove', 'deleteRole([data])')]);

        $scope.tableRoleInterested = {
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn(RolesNameColumn.getColumn()).withTitle(RolesNameColumn.getTitle()),
                DTColumnBuilder.newColumn('id').withOption('width', '90px').withOption('className', 'text-center').withTitle(RolesActionColumn.getTitle()).notSortable().renderWith(RolesActionColumn.getRender)
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux : { langColumn: Language.getActiveColumn() },
            reloadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = { langColumn: Language.getActiveColumn() };
                this.table.reloadData(function callbackInstance(json){}, resetPaging);
            }
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.role_interested') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mb-md-0 mb-sm-pt mr-sm-pt',undefined,'#admin-role-interested','fa fa-plus','global.commonAdmin.modal.role.titleNew',undefined,'openNewRole')
                ];
            }
        })

        HeaderService.changeState($state.current)

        $scope.saveRole = function($modal) {
            if($modal.annexaFormly.form.$valid) {
                var data = $modal.annexaFormly.model.modal_body;

                var model = {
                    language1: data.language1,
                    language2: 'NotTranslated',
                    language3: 'NotTranslated',
                    canUseRegisterEntry: data.canUseRegisterEntry_true || false,
                    canUseRegisterOutput: data.canUseRegisterOutput_true || false,
                    canUseDossier: data.canUseDossier_true || false
                };

                if(data.language2) {
                    model.language2 = data.language2;
                }

                if(data.language3) {
                    model.language3 = data.language3;
                }

                AdminFactory.newRoleInterested(model)
                    .then(function (data) {
                        $scope.tableRoleInterested.reloadInternalData(false);
                        $modal.close();
                    })
            }
        };

        $scope.openNewRole = function (id, readonly) {
           var modal = CommonAdminModals.roleInterestedNew;
           modal.annexaFormly.model = {};
           modal.annexaFormly.model.modal_body = {};
           modal.annexaFormly.options = {};
           modal.annexaFormly.options.formState = { readOnly: readonly};
           modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.roleInterested.canUseRegisterEntry'), value: true }];
           modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.roleInterested.canUseRegisterOutput'), value: true }];
           modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.roleInterested.canUseDossier'), value: true }];
           AnnexaFormlyFactory.showModal("modalRoles", modal, $scope.saveRole, false);
       }

        $scope.updateRole = function (val, prop) {
        	var model = this.data;
        	
            switch (prop) {
                case 'language1':
                case 'language2':
                case 'language3':
                	model.language1 = val.language1;
                	model.language2 = val.language2;
                	model.language3 = val.language3;
                    break;
                case 'canUseRegisterEntry':
                	model[prop] = val;
                    break;
                case 'canUseRegisterOutput':
                	model[prop] = val;
                    break;
                case 'canUseDossier':
                	model[prop] = val;
                    break;
            }

            var self = this;

            AdminFactory.updateRoleInterested(model)
                .then(function (data) {
                    var decodedData = JSOG.decode(data.data);
                    $scope.tableRoleInterested.reloadInternalData(false);
                }).catch(function (error) {
            });
        };
        
        $scope.viewRole = function (id) {
            RestService.findOne('RoleInterested', id)
                .then(function (data) {
                    var modal = CommonAdminModals.roleInterestedEdit;
                    modal.data = JSOG.decode(data.data);
                    AdminFactory.roleEdit = angular.copy(modal.data);
                    delete AdminFactory.roleEdit.content;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updateRole = $scope.updateRole;
                    modal.printCanUse = function (model) {
                        if(model) {
                            return $filter('translate')('global.commonAdmin.modal.roleInterested.yes');
                        } else {
                            return $filter('translate')('global.commonAdmin.modal.roleInterested.no');
                        }
                    };
                    AnnexaModalFactory.showModal("modalRoleEdit", modal);
                })
        };

        $scope.deleteRole = function (id) {
        	if(id) {
        		DialogsFactory.confirm('global.commonAdmin.modal.roleInterested.confirmDelete', 'global.books.list.executeJobMessageConfirm')
                .then(function (data) {
	        		RestService.checkDelete('./api/tram/role_interested/check_delete/' + id)
	                .then(function(data) {
	                	if(data) {
	                		RestService.delete('./api/tram/role_interested/' + id)
		                        .then(function(data) {
		                            $scope.tableRoleInterested.reloadInternalData(false);
		                        }).catch(function (error) {
		                            console.log(error);
		                    })
	                	} else {
	                		DialogsFactory.error($filter('translate')('global.commonAdmin.modal.roleInterested.usedRoleError'));
	                	}
	                });
                }).catch(function (data) {
	 	               //Empty
	 	       });	
        	}
        };

        $scope.doSearchFilter=function(queued) {
            $scope.tableRoleInterested.reloadInternalData(true);
        }
    }]);